<template>
  <div class="overlay-3d">
    <button
      class="head"
      type="button"
      @click.prevent="closeFrame"
    >
      <Icon name="next" />
      <div class="title" @click="printInConsole">{{`${content?.name} ${modelName}`}}</div>
    </button>
    <iframe
      class="model"
      :src="`${content?.path}`"
      frameborder="0"
      sandbox="allow-same-origin || allow-scripts || allow-forms"
    ></iframe>
  </div>
</template>

<script>
import Icon from '../../Icon.vue';

export default {
  name: 'Overlay3D',
  props: {
    content: {
      type: Object,
    },
    activeSlide: {
      type: Number,
    },
    models: {
      type: Object,
    },
  },
  components: { Icon },
  emits: ['CloseFrame'],
  computed: {
    baseUrl() {
      return window.location.origin;
    },
    modelName() {
      return this.models[this.activeSlide]?.Name;
    },
  },
  methods: {
    closeFrame() {
      this.$emit('close-frame');
    },
    printInConsole() {
      console.log(this.content);
      console.log(this.models);
      // console.log(this.modelName);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-3d {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color(dark);
  z-index: 30;

  .head {
    position: relative;
    flex-shrink: 0;
    color: color(basic-light);

    @include wRule(
      width,
      (
        xl: 120,
        lg: 94,
        md: 76,
        sm: 60,
      )
    );
  }

  .model {
    flex: 1;
    overflow: hidden;
  }

  .title {
    position: absolute;
    left: 50%;
    display: block;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: end;
    line-height: 1;
    transform: rotate(-90deg) translate3d(-100%, -50%, 0);
    transform-origin: 0 0;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 28,
        md: 26,
        sm: 15,
      )
    );

    @include wRule(
      top,
      (
        xl: 133,
        lg: 95,
        md: 87,
        sm: 28,
      )
    );

    @include rule(width, (xl: 49vh, lg: 57vh, md: 54vh, sm: 69vh));

    @media only screen and (max-height: 920px) {
      @include wRule(
      font-size,
        (
          xl: 28,
          lg: 28,
          md: 26,
          sm: 15,
        )
      );
    }

    @media only screen and (max-height: 690px) {
      @include wRule(
        font-size,
        (
          lg: 24,
          md: 15,
          sm: 15,
        )
      );

      @include rule(width, (lg: 54vh, md: 69vh, sm: 69vh));

      @include wRule(
        top,
        (
          xl: 133,
          lg: 95,
          md: 28,
          sm: 28,
        )
      );
    }
  }

  .icon {
    position: absolute;
    left: 50%;
    transform: translate3d(-30%, 0, 0);

    @include wRule(
      height,
      (
        xl: 40,
        lg: 30,
        md: 30,
        sm: 20, // 22
      )
    );

    @include wRule(
      bottom,
      (
        xl: 298,
        lg: 158,
        md: 200,
        sm: 33,
      )
    );

    @media only screen and (max-height: 920px) {
      @include wRule(
        bottom,
        (
          xl: 158,
          lg: 158,
          md: 200,
          sm: 33,
        )
      );
    }

    @media only screen and (max-height: 690px) {
        @include wRule(
        height,
        (
          xl: 40,
          lg: 30,
          md: 20,
          sm: 20, // 22
        )
      );

      @include wRule(
        bottom,
        (
          xl: 298,
          lg: 100,
          md: 33,
          sm: 33,
        )
      );
    }
  }
}
</style>
