<template>
  <div class="page-container">
    <Breadcrumbs />
    <div class="fleet">
      <FleetSection
        :content="pageContent?.SectionFleet"
        id="fleet"
        ref="fleet"
      />
    </div>
  </div>
</template>

<script>
import FleetSection from './components/FleetSection.vue';
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import api from '@/tools/api';

export default {
  name: 'Fleet',
  components: { FleetSection, Breadcrumbs },
  data() {
    return {
      pageLoading: true,
      pageContent: [],
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('page-about-content', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    async getFleetData() {
      const fleetGroups = await api('fleet-groups', false);
      this.$store.commit('setFleetGroups', fleetGroups.data);
      const fleetModels = await api('fleet-models', false);
      this.$store.commit('setFleetModels', fleetModels.data);
      const fleetParams = await api('fleet-params-names', true);
      this.$store.commit('setFleetParams', fleetParams.data);
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  created() {
    this.getPageContent();
    this.getFleetData();
  },
};
</script>

<style lang="scss" scoped>
.bases {
  display: flex;
  flex-wrap: wrap;
}
</style>
