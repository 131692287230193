<template>
  <component
    :is="tag"
    class="link"
  >
    <div class="link__wrapper">
      <slot name="start"></slot>
      <span class="link__text">
        <slot></slot>
      </span>
      <slot name="end"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'Link',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  display: inline-block;
  color: currentColor;
  line-height: 1.2;
  $b: &;

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__text {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: 0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
  }

  @media (hover: hover) {
    &:hover {
      #{$b} {
        &__text {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  &.underline {
    .link__text {
      &::before {
        transform: scaleX(1);
      }
    }

    @media (hover: hover) {
      &:hover {
        .link__text {
          &::before {
            transform: scaleX(0);
          }
        }
      }
    }
  }

  ::v-deep(.icon) {
    flex-shrink: 0;

    @include wRule(
      width,
      (
        xl: 34,
        lg: 28,
        md: 25,
        sm: 48,
      )
    );

    @include wRule(
      height,
      (
        xl: 34,
        lg: 28,
        md: 25,
        sm: 48,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 21,
          lg: 19,
          md: 13,
          sm: 43,
        )
      );
    }

    &_arrow {
      @include wRule(
        height,
        (
          xl: 15,
          lg: 11,
          md: 10,
          sm: 18,
        )
      );
      @include wRule(
        width,
        (
          xl: 58,
          lg: 42,
          md: 40,
          sm: 79,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 30,
            lg: 30,
            md: 30,
            sm: 40,
          )
        );
      }
    }

    &_long-arrow {
      transition: transform 300ms ease;
      transform-origin: 0% 50%;

      @include screen("sm", max) {
        opacity: 0.5;
      }

      @include wRule(
        margin-left,
        (
          xl: 30,
          lg: 38,
          md: 26,
          sm: 44,
        )
      );

      @include wRule(
        width,
        (
          xl: 80,
          lg: 80,
          md: 70,
          sm: 80,
        )
      );

      @include wRule(
        height,
        (
          xl: 17,
          lg: 17,
          md: 15,
          sm: 17,
        )
      );
    }

    &_pin-outline {
      @include wRule(
        margin-left,
        (
          xl: 14,
          lg: 12,
          md: 14,
          sm: 18,
        )
      );

      @include wRule(
        width,
        (
          xl: 28,
          lg: 22,
          md: 12,
          sm: 19,
        )
      );

      @include wRule(
        height,
        (
          xl: 28,
          lg: 22,
          md: 18,
          sm: 26,
        )
      );
    }
  }

  &.is-inview-animation-fade-down ::v-deep(.icon) {
    &_arrow,
    &_long-arrow {
      transform: scaleX(0);
    }
  }

  &.is-inview-animation-active ::v-deep(.icon) {
    &_arrow,
    &_long-arrow {
      transform: scaleX(1);
    }
  }
}
</style>
