<template>
  <article
    class="person-card"
    v-observe-visibility="animation"
  >
    <div class="preview">
      <img
        v-if="data?.Photo?.url"
        :src="getUrl(data?.Photo?.formats?.thumbnail?.url)"
        :data-src="getUrl(data?.Photo?.url
        )"
        :alt="data?.Name"
        class="image lazyload"
      >
      <!-- <img
        v-if="data?.Photo?.url"
        :src="require(`@/${data?.Photo?.url}`)"
        :data-src="require(`@/${data?.photo?.url}`)"
        :alt="data?.name"
        class="image lazyload"
      > -->
    </div>
    <div class="content">
      <div
        class="description"
        v-html="markdownToHTML(data?.Description)"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
      />

      <div
        class="about"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
      >
        <h3 class="name">{{ data?.Name }}</h3>
        <p class="position">{{ data?.Position }}</p>
      </div>

      <div
        class="contacts"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 600}"
      >
        <p
          class="contact-item"
          v-if="data?.Phone"
        >
          <a
            :href="`tel:${data?.Phone.replace(/ /gi, '').replace('(', '').replace(')', '').replace(/-/gi, '')}`"
            target="_blank"
          >{{data?.Phone}}</a>
        </p>
        <p
          class="contact-item"
          v-if="data?.Email"
        >
          <a
            :href="`mailto:${data?.Email}`"
            target="_blank"
          >{{data?.Email}}</a>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import getUrl from '../../../../../tools/getUrl';
import markdownIt from 'markdown-it';
import { animationMixin } from '@/components/mixins/animation';

const md = markdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'PersonCard',
  mixins: [animationMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrl,
    markdownToHTML(content) {
      return md.render(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.person-card {
  display: flex;
  width: 100%;
  color: color(basic);
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;

  @include wRule(
    max-width,
    (
      xl: 1394,
      lg: 994,
      md: 774,
      sm: 550,
    )
  );

  @include rule(
    flex-direction,
    (
      xl: row,
      sm: column,
    )
  );

  @include wRule(
    margin,
    (
      sm: 110 0,
    )
  );

  .description {
    position: relative;

    @include rule(
      line-height,
      (
        xl: 1.6,
        lg: 1.4,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    ::v-deep(> :not(:last-child)) {
      @include wRule(
        margin-bottom,
        (
          xl: 16,
          lg: 15,
          md: 15,
          sm: 24,
        )
      );
    }

    // &::before {
    //   @include screen("sm", max) {
    //     @include pseudo;

    //     width: pxtovw(64, sm);
    //     height: pxtovw(3, sm);
    //     background-color: color(basic);
    //     left: 0;
    //     top: pxtovw(-43, sm);
    //   }
    // }
  }

  a {
    position: relative;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  h3 {
    line-height: 1.2;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );
  }

  .preview {
    position: relative;
    display: block;
    overflow: hidden;
    flex: none;
    font-size: 0;
    line-height: 0;

    @include wRule(
      width,
      (
        xl: 568,
        lg: 402,
        md: 316,
        sm: 100%,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      height: pxtovw(571, sm);
    }

    img {
      font-size: 0;
      line-height: 0;

      @include fill-img;

      bottom: auto;
    }
  }

  .content {
    display: block;
    box-sizing: border-box;
    flex: 1;
    background-color: color(basic-light);
    line-height: 1.6;

    @include wRule(
      padding,
      (
        xl: 189 168 166 110,
        lg: 100 100 90 77,
        md: 110 61 100,
        sm: 60 40 74,
      )
    );
  }

  .about {
    line-height: 1.4;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 68,
          lg: 52,
          md: 44,
          sm: 66,
        )
      );
    }
  }

  .position {
    color: color(gray);
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );
  }

  .contacts {
    display: flex;
    justify-self: flex-start;
    align-items: center;
    line-height: 1.4;

    @include screen("sm", max) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 18,
          lg: 24,
          md: 18,
          sm: 14,
        )
      );
    }
  }

  .contact-item {
    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 85,
          lg: 72,
          md: 60,
          sm: 0,
        )
      );
    }
  }
}
</style>
