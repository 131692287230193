<template>
  <div class="fleet-popup">
    <div class="content">
      <Swiper
        class="slider"
        @swiper="swiperInstance = $event"
        :speed="500"
        :effect="'fade'"
        :fade-effect="{crossFade: true}"
        :slides-per-view="1"
        :initialSlide="getInitialSlide"
        :simulateTouch="false"
        :height-auto="'auto'"
      >
        <template
          v-for="(group, groupId) in content?.groups"
          :key="groupId"
        >
          <SwiperSlide>
            <div class="card">
              <div class="title">{{group?.name}}</div>
              <Tabs
                :tabs="group.models"
                :groupId="groupId"
                v-slot="{ activeTab }"
                :initActive="getInitialTab(groupId)"
                @tab-switch="tabActivation"
                @tab-init="tabListUpdated"
              >
                <FeatureTable
                  :content="activeTab"
                  :headers="content?.charNames"
                />
              </Tabs>
            </div>
          </SwiperSlide>
        </template>
      </Swiper>
      <SliderArrows
        :swiper="swiperInstance"
        class="theme-white navigation"
      />
    </div>
    <div class="preview">
      <transition name="fade">
        <BgMedia
          :source="getActiveGroup?.preview3d"
          :key="getActiveGroup?.preview3d"
        />
      </transition>
      <transition name="fade">
        <div class="control" v-if="getActiveGroup?.path">
          <div class="control-area">
            <Icon name="globus" />
            <VButton
              :tag="button"
              type="button"
              @click.prevent="openModel"
            >
              <template #default>{{pageContent?.ButtonText}}</template>
            </VButton>
          </div>
        </div>
      </transition>
    </div>
    <transition
      name="overlay-slide"
      mode="in-out"
    >
      <Overlay3D
        :content="getActiveGroup"
        :activeSlide="swiperInstance.activeIndex
        "
        :models="getTabList"
        @CloseFrame="closeModel"
        v-if="frameActive"
      />
    </transition>
  </div>
</template>

<script>
import SwiperCore, { EffectFade } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue/';

import 'swiper/swiper.scss';
import Tabs from './components/Tabs.vue';
import FeatureTable from './components/FeatureTable.vue';
import SliderArrows from '../SliderArrows.vue';
import BgMedia from '../BgMedia.vue';
import Icon from '../Icon.vue';
import VButton from '../VButton.vue';
import Overlay3D from './components/Overlay3D.vue';
import getUrl from '../../tools/getUrl';
import api from '../../tools/api';

SwiperCore.use([EffectFade]);

export default {
  name: 'FleetPopup',
  components: {
    Swiper,
    SwiperSlide,
    Tabs,
    FeatureTable,
    SliderArrows,
    BgMedia,
    Icon,
    VButton,
    Overlay3D,
  },
  props: {
    initialData: { type: Object },
    content: { type: Object },
  },
  data() {
    return {
      swiperInstance: {},
      activeModel: null,
      frameActive: false,
      pageContent: {},
      tabList: {},
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
    getInitialSlide() {
      let result = 0;
      const array = this.content?.groups;
      if (array) {
        array.forEach((el, i) => {
          if (el.group === this.initialData.group) {
            result = i;
          }
        });
      }
      return result;
    },
    getActiveGroup() {
      const currentSlide = this.swiperInstance
        ? this.swiperInstance.activeIndex
        : this.getInitialSlide;
      const group = this.content?.groups[currentSlide];
      return group;
    },
    getActiveModel() {
      const initialGroup = this.content?.groups[this.getInitialSlide];
      const initialModel = initialGroup?.models[this.getInitialTab(this.getInitialSlide)];
      let result = initialModel;
      if (this.activeModel) {
        result = this.activeModel;
      }
      return result;
    },
    getTabList() {
      return this.tabList;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('fleet-popup', true);
      this.pageContent = results.data;
    },
    getInitialTab(slideNumber) {
      const initialGroup = this.getInitialSlide;
      let result = 0;
      if (initialGroup === slideNumber) {
        const array = this.content?.groups[initialGroup]?.models;
        array.forEach((el, i) => {
          if (el.id === this.initialData.model) {
            result = i;
          }
        });
      }
      return result;
    },
    tabActivation(model, group) {
      this.tabList[group] = model;
      this.activeModel = model;
    },
    tabListUpdated(model, group) {
      this.tabList[group] = model;
      console.log(this.tabList);
    },
    openModel() {
      document.documentElement.classList.add('is-model-open');
      this.frameActive = true;
    },
    closeModel() {
      document.documentElement.classList.remove('is-model-open');
      this.frameActive = false;
    },
    getUrl,
  },
  created() {
    this.getPageContent();
  },
  mounted() {

  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
};
</script>

<style lang="scss" scoped>
.fleet-popup {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: color(dark);

  @include screen("sm", max) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    box-sizing: border-box;

    @include wRule(
      width,
      (
        xl: 726,
        lg: 606,
        md: 460,
        sm: 100%,
      )
    );

    @include wRule(
      padding,
      (
        xl: 75 164 75 240,
        lg: 75 116 75 165,
        md: 75 88 75 125,
        sm: 85 45,
      )
    );

    @include screen("sm", max) {
      order: 2;
    }
  }

  .tabs {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 60,
          lg: 48,
          md: 44,
          sm: 40,
        )
      );
    }
  }

  .slider-arrows {
    ::v-deep(.arrow) {
      &:not(:last-child) {
        @include wRule(
          margin-right,
          (
            xl: 60,
            lg: 40,
            md: 40,
            sm: 88,
          )
        );
      }
    }

    @include screen("sm", max) {
      margin: 0 auto;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 95,
          lg: 90,
          md: 60,
          sm: 78,
        )
      );
    }
  }

  .slider {
    width: 100%;

    .swiper-slide {
      pointer-events: none;

      &.swiper-slide-active {
        pointer-events: auto;
      }
    }
  }

  .preview {
    position: relative;
    display: flex;
    flex: 1;
    order: 1;

    @include screen("sm", max) {
      width: 100%;
    }

    .bg-media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: color(basic);
        opacity: 0.48;
        z-index: 1;
      }
    }
  }

  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(11px);
    z-index: 2;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 45,
        lg: 45,
        md: 45,
        sm: 45,
      )
    );
  }

  .control-area {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    @include wRule(
      width,
      (
        xl: 393,
        lg: 340,
        md: 299,
        sm: 100%,
      )
    );

    @include wRule(
      height,
      (
        xl: 393,
        lg: 340,
        md: 299,
        sm: 100%,
      )
    );

    ::v-deep(.icon) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      fill: color(gray2);
    }

    ::v-deep(.v-button) {
      font-weight: 700;

      @include wRule(
        padding,
        (
          xl: 0 47,
          lg: 0 37,
          md: 0 32,
          sm: 0 39,
        )
      );

      @include rule(
        border-width,
        (
          xl: 3px,
          lg: 3px,
          md: 3px,
          sm: 3px,
        )
      );

      @include wRule(
        height,
        (
          xl: 52,
          lg: 45,
          md: 40,
          sm: 48,
        )
      );

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 18,
          md: 16,
          sm: 20,
        )
      );
    }
  }

  .title {
    display: block;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 28,
        md: 26,
        sm: 40,
      )
    );
  }
}
</style>
