<template>
  <div class="page-container">
    <BgMedia
      :media="getBackground(pageContent?.ScreenOne)"
      class="bg-fixed"
    />

    <Swiper
      class="main-slider"
      :speed="500"
      :init="false"
      :autoHeight="true"
      :slidesPerView="'auto'"
      :followFinger="false"
      :direction="'veritcal'"
      :observer="true"
      :observeSlideChildren="true"
      :keyboard="{enabled: true}"
      :resizeObserver="true"
      :simulateTouch="false"
      :resistance="false"
      :preventInteractionOnTransition="true"
      @swiper="swiperInstance = $event"
      @slideNextTransitionStart="slideMoveDown"
      @slidePrevTransitionStart="slideMoveUp"
      @reachEnd="sliderReachEnd"
      @fromEdge="sliderGoAwayFromEnd"
      @activeIndexChange="slideActiveChange"
      @afterInit="listenInit"
    >
      <SwiperSlide v-slot="{ isActive, isPrev }">
        <LeadSection
          data-screen-section="0"
          :class="{'is-leave-animation': isPrev && isDown,'is-enter-animation': (isActive && !isDown)}"
          :data-screen-slide-active="isActive"
          :bg="getBackground(pageContent?.ScreenOne)"
          :content="pageContent?.ScreenOne"
        />
      </SwiperSlide>
      <SwiperSlide v-slot="{isActive, isPrev, isNext }">
        <ServiceSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="1"
          :data-screen-slide-active="isActive"
          :content="pageContent?.ScreenServices"
          :bg="getBackground(pageContent?.ScreenServices)"
        />
      </SwiperSlide>
      <SwiperSlide v-slot="{isActive, isPrev, isNext}">
        <BasesSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="2"
          :data-screen-slide-active="isActive"
          :content="pageContent?.ScreenBase"
          :bg="getBackground(pageContent?.ScreenBase)"
        />
      </SwiperSlide>

      <SwiperSlide v-slot="{isActive, isPrev, isNext}">
        <InstrumentSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="3"
          id="tools"
          :data-screen-slide-active="isActive"
          :content="pageContent?.ScreenInstruments"
        />
      </SwiperSlide>

      <!-- <SwiperSlide v-slot="{isActive, isPrev, isNext}">
        <AllianceSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          data-screen-section="3"
          :data-screen-slide-active="isActive"
          :content="pageContent?.ScreenAlliance"
        />
      </SwiperSlide> -->

      <SwiperSlide v-slot="{isActive, isPrev, isNext }">
        <PageFooter :class="{'is-leave-animation': isPrev, 'is-active-animation': isActive, 'is-enter-animation': isNext}" />
      </SwiperSlide>
    </Swiper>

    <PageAnchors
      v-show="!isFooterNow"
      :isShown="!pageLoading"
      :slideActive="slideActive"
      :screenNames="pageContent"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Keyboard } from 'swiper/core';
import 'swiper/swiper.scss';

// import AllianceSection from './components/AllianceSection.vue';
import LeadSection from './components/LeadSection.vue';
import BasesSection from './components/BasesSection.vue';
import ServiceSection from './components/ServiceSection/ServiceSection.vue';
import InstrumentSection from './components/InstrumentSection.vue';
import PageAnchors from './components/PageAnchors.vue';
import BgMedia from '../../components/BgMedia.vue';
import PageFooter from '../../components/PageFooter.vue';
import { animationMixin } from '../../components/mixins/animation';
import smoothScrollToAnchor from '../../tools/smoothScrollToAnchor';
import api from '../../tools/api';

SwiperCore.use([Keyboard]);

export default {
  name: 'Home',
  components: {
    LeadSection,
    BasesSection,
    ServiceSection,
    // AllianceSection,
    InstrumentSection,
    PageAnchors,
    Swiper,
    SwiperSlide,
    BgMedia,
    PageFooter,
  },
  mixins: [animationMixin],
  data() {
    return {
      isDown: this.breakpoint !== 'sm',
      swiperInstance: undefined,
      lastScrollerPosition: 0,
      slideActive: 0,
      pageContent: {},
      screenConsulting: {},
      pageLoading: true,
      consultingLoading: true,
      transitionNextStart: false,
      transitionPrevStart: false,
      pageActive: false,
      isTransition: false,
      isSliderScroll: true,
      isFooterNow: false,
      isTools: this.$route.hash === 'tools',
    };
  },
  emits: [
    'pageSliderScrollDown',
    'pageSliderScrollUp',
    'pageSliderReachFooter',
    'pageSliderGoAwayFooter',
  ],
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    content() {
      return this.pageContent?.screen_main;
    },
    startNumber() {
      return this.isTools ? 3 : 0;
    },
  },
  methods: {
    scrollToId(id) {
      const target = document.getElementById(id);
      if (target) {
        smoothScrollToAnchor(target);
      }
    },
    setPosition() {
      if (this.isTools) {
        if (this.breakpoint !== 'sm') {
          this.swiperInstance.slideTo(3);
        }
        this.scrollToId('tools');
      }
    },
    getBackground(screen) {
      return {
        background: screen?.Background,
        backgroundMobile: screen?.BackgroundMobile,
      };
    },
    slideMoveDown() {
      this.$emit('pageSliderScrollDown', this.swiperInstance?.activeIndex);
      this.transitionNextStart = true;
      this.isDown = true;
    },
    slideMoveUp() {
      this.$emit('pageSliderScrollUp', this.swiperInstance?.activeIndex);
      this.transitionPrevStart = true;
      this.isDown = false;
    },
    sliderReachEnd() {
      this.isFooterNow = true;
      this.$emit('pageSliderReachFooter');
    },
    sliderGoAwayFromEnd() {
      this.isFooterNow = false;
      this.$emit('pageSliderGoAwayFooter');
    },
    swiperManage() {
      if (this.breakpoint === 'sm') {
        this.swiperInstance.destroy(false, true);
      } else {
        this.swiperInstance.init();
        this.swiperInstance.update();
      }

      setTimeout(() => {
        this.setPosition();
      }, 200);
    },
    stopMouseWheel(e) {
      e.preventDefault();
    },
    slideActiveChange(sw) {
      this.slideActive = sw.activeIndex;
    },
    async getPageContent() {
      const results = await api('page-main-content', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    manualsScrolling(evt) {
      const swp = this.swiperInstance;

      if (this.isSliderScroll) {
        evt.preventDefault();
        evt.stopPropagation();
        const diff = window.lethargy.check(evt);
        if (diff !== false && !this.swiperInstance.animating) {
          if (diff > 0) {
            swp.slidePrev(500);
          } else {
            swp.slideNext(500);
          }
        }
      }
    },
    listenInit() {
      this.pageActive = true;

      window.addEventListener('wheel', this.manualsScrolling, {
        passive: false,
      });
    },
  },
  watch: {
    breakpoint() {
      this.swiperManage();
    },
    lang() {
      this.getPageContent();
    },
    $route(route) {
      console.log(`route: ${route}`);
      this.isTools = route.hash === 'tools';
      setTimeout(() => {
        this.setPosition();
      }, 300);
    },
  },
  created() {
    this.getPageContent();
  },
  mounted() {
    this.swiperManage();
  },
  beforeUnmount() {
    window.removeEventListener('wheel', this.manualsScrolling, {
      passive: false,
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page-container {
  position: relative;
  width: 100%;
  min-height: 100%;
}

.main-slider {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  @include screen("sm", max) {
    height: auto;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100vh, color(dark) 100vh, color(dark) 100%);
    background-repeat: no-repeat;
  }

  ::v-deep(> .swiper-wrapper) {
    flex-direction: column;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);
  }

  .slide-wrapper {
    overflow: auto;
    max-height: 100%;
  }
}

.page-section {
  position: relative;
  transform-origin: 100% 100%;
}
</style>
