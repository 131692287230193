<template>
  <div class="team-card">
    <BgMedia :media="{background: content?.Background, backgroundMobile: content?.BackgroundMobile}" />
    <div class="content">
      <h3 class="title">{{content?.Name}}</h3>
      <div
        class="text"
        v-html="content?.Description"
      ></div>
    </div>
  </div>
</template>

<script>
import BgMedia from '../../../components/BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'TeamCard',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.team-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  .title {
    position: relative;
    display: inline-flex;
    line-height: 1.4;
    font-weight: 700;
    text-transform: uppercase;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 22,
        md: 20,
        sm: 34,
      )
    );

    &::after {
      @include pseudo;

      bottom: -0.4em;
      left: 0;
      width: 100%;
      background-color: color(main);
      transition: transform $ease-main;
      transform: scaleX(0);
      transform-origin: 0 50%;

      @include wRule(
        height,
        (
          xl: 3,
          lg: 3,
          md: 3,
          sm: 4,
        )
      );

      @include screen("sm", max) {
        transform: scaleX(1);
        bottom: -0.2em;
      }
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 46,
          lg: 28,
          md: 30,
          sm: 75,
        )
      );
    }
  }

  .text {
    display: block;
    width: 100%;
    color: color(bg);
    line-height: 1.4;
    transition: opacity 0.3s ease;
    opacity: 0;

    @include wRule(
      max-width,
      (
        xl: 470,
        lg: 340,
        md: 270,
        sm: none,
      )
    );

    @include wRule(
      height,
      (
        xl: 200,
        lg: 160,
        md: 160,
        sm: auto,
      )
    );

    @include screen("sm", max) {
      opacity: 1;
    }
  }

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;

    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0) 0%,
        rgba(20, 24, 31, 0.8) 100%
      );
      z-index: 2;

      @include screen("sm", max) {
        background: linear-gradient(
          180deg,
          rgba(20, 24, 31, 0) 0%,
          rgba(20, 24, 31, 0.85) 100%
        );
      }
    }
  }

  .content {
    position: relative;
    display: block;
    box-sizing: border-box;
    z-index: 2;
    transition: transform $ease-main;
    transform: translate3d(0, 34%, 0);

    @include screen("lg", max) {
      transform: translate3d(0, 42%, 0);
    }

    @include screen("md", max) {
      transform: translate3d(0, 42%, 0);
    }

    @include screen("sm", max) {
      transform: translate3d(0, 0, 0);
    }

    @include wRule(
      padding,
      (
        xl: 20 240 50 142,
        lg: 20 165 35 108,
        md: 20 110 35 86,
        sm: 20 45 220,
      )
    );
  }

  &.big {
    .content {
      transform: translate3d(0, 32%, 0);

      @include screen("lg", max) {
        transform: translate3d(0, 38%, 0);
      }

      @include screen("md", max) {
        transform: translate3d(0, 38%, 0);
      }

      @include screen("sm", max) {
        transform: translate3d(0, 0, 0);
      }

      @include wRule(
        padding,
        (
          xl: 220 142 138 240,
          lg: 160 78 100 165,
          md: 160 50 100 123,
          sm: 120 45 80,
        )
      );
    }
  }

  &:hover {
    .title {
      &::after {
        transform: scaleX(1);
      }
    }

    .text {
      opacity: 1;
    }

    .content {
      transform: translate3d(0, 15%, 0);

      @include screen("lg", max) {
        transform: translate3d(0, 15%, 0);
      }

      @include screen("md", max) {
        transform: translate3d(0, 15%, 0);
      }

      @include screen("sm", max) {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>
