<template>
  <div class="fleet-table">
    <div
      class="wrapper"
      ref="wrapper"
    >
      <div
        class="header"
        v-if="breakpoint!=='sm'"
      >
        <template
          v-for="(thead, thid) in content?.headers"
          :key="thid"
        >
          <div class="hcell">{{thead}}</div>
        </template>
      </div>
      <div class="body">
        <template
          v-for="group in content?.groups"
          :key="group?.name"
        >
          <div class="row">
            <div class="brand">
              <div class="logo"><img
                  :src="getUrl(group?.logo?.url)"
                  :alt="group?.name"
                ></div>
            </div>
            <div class="inner-table">
              <div
                class="inner-header"
                v-if="breakpoint==='sm'"
              >
                <template
                  v-for="(thead, thid) in content?.headers"
                  :key="thid"
                >
                  <div
                    class="hcell"
                    v-if="thid !== 'Brand'"
                  >{{thead}}</div>
                </template>
              </div>
              <div class="inner-body">
                <template
                  v-for="(helicopter, helicopterId) in group?.helicopters"
                  :key="`${helicopter?.Model}-${helicopterId}`"
                >
                  <div
                    class="inner-row"
                    @click.prevent="openPopup({group: group?.group, model: helicopter?.Model})"
                  >

                    <div class="cell">
                      {{getModelData(helicopter?.Model)?.Name}}
                    </div>
                    <div class="cell">
                      {{helicopter?.Year}}
                    </div>
                    <div class="cell">
                      {{helicopter?.RegistrationNumber}}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import getUrl from '../../../tools/getUrl';

export default {
  name: 'FleetTable',
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  emits: ['OpenPopup'],
  methods: {
    stopWheelHandler(evt) {
      evt.stopPropagation();
    },
    openPopup(data) {
      this.$emit('open-popup', data);
    },
    getModelData(id) {
      return this.$store.getters.getFleetModelById(id);
    },
    getUrl,
  },
  mounted() {
    this.$refs.wrapper.addEventListener('wheel', this.stopWheelHandler, {
      passive: false,
    });
  },
  beforeUnmount() {
    this.$refs.wrapper.removeEventListener('wheel', this.stopWheelHandler, {
      passive: false,
    });
  },
};
</script>

<style lang="scss" scoped>
.fleet-table {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  @include wRule(
    padding,
    (
      xl: 0 120 0 245,
      lg: 0 85 0 175,
      md: 0 0 0 65,
      sm: 0,
    )
  );

  .wrapper {
    display: block;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;

    @include wRule(
      padding-right,
      (
        xl: 115,
        lg: 85,
        md: 64,
        // sm: 20,
        sm: 0,
      )
    );

    @include wRule(
      max-height,
      (
        xl: pxtovh(505, xl),
        lg: pxtovh(366, lg),
        md: pxtovh(345, md),
        // sm: 746,
        sm: none,
      )
    );

    @include native-scroll(color(darkborder), 6, 6, 4);
  }

  .header {
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: color(dark);
    display: grid;
    grid-template-columns: pxtovw(444, xl) 2fr 1.8fr 0.8fr;
    border-bottom: 1px solid color(darkborder);

    @include screen("lg", max) {
      grid-template-columns: pxtovw(320, lg) 2fr 1.6fr 1fr;
    }

    @include screen("md", max) {
      grid-template-columns: pxtovw(300, md) 2fr 1.6fr 1fr;
    }
  }

  .body {
    display: block;
    width: 100%;
  }

  .row {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 43 0,
        lg: 30 0,
        md: 28 0,
        sm: 50 0,
      )
    );

    @include screen("sm", max) {
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      border-bottom: 1px solid color(darkborder);
    }

    &:first-child {
      @include screen("sm", max) {
        border-top: 1px solid color(darkborder);
      }
    }

    &:last-child {
      @include screen("sm", max) {
        border-bottom: 1px solid color(darkborder);
      }
    }
  }

  .inner-table {
    display: block;
    flex: 1;

    @include screen("sm", max) {
      flex: none;
      width: 100%;
      margin-top: pxtovw(48, sm);
    }
  }

  .inner-header {
    display: flex;
    width: 100%;
  }

  .inner-body {
    display: block;
    width: 100%;
  }

  .inner-row {
    display: grid;
    grid-template-columns: 2fr 1.8fr 0.8fr;
    width: 100%;
    cursor: pointer;
    transition: background 0.3s ease;

    @include screen("lg", max) {
      grid-template-columns: 2fr 1.6fr 1fr;
    }

    @include screen("sm", max) {
      grid-template-columns: pxtovw(253, sm) repeat(2, 1fr);
    }

    &:hover {
      background-color: color(hover);
    }
  }

  .brand {
    box-sizing: border-box;
    flex-shrink: 0;
    width: pxtovw(444, xl);

    @include wRule(
      padding-left,
      (
        xl: 45,
        lg: 30,
        md: 25,
        sm: 0,
      )
    );

    @include wRule(
      width,
      (
        lg: 320,
        md: 300,
        sm: 100%,
      )
    );
  }

  .logo {
    display: block;
    width: 100%;

    @include wRule(
      height,
      (
        xl: 40,
        lg: 30,
        md: 28,
        sm: 60,
      )
    );

    img {
      display: inline-block;
      height: 100%;
      max-width: 100%;
    }
  }

  .hcell {
    line-height: 1.6;
    letter-spacing: -0.02em;
    font-weight: 700;
    box-sizing: border-box;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 14,
        sm: 21,
      )
    );

    @include wRule(
      padding-bottom,
      (
        xl: 10,
        lg: 5,
        md: 5,
        sm: 22,
      )
    );

    &:not(:first-child) {
      flex: 1;
    }

    &:first-child {
      flex-shrink: 0;

      @include wRule(
        width,
        (
          sm: 253,
        )
      );
    }
  }

  .cell {
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.02em;
    box-sizing: border-box;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    @include wRule(
      padding,
      (
        xl: 10 0,
        lg: 7 0,
        md: 6 0,
        sm: 6 0,
      )
    );
  }
}
</style>
