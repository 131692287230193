<template>
  <section class="tabs">

    <div class="navigation">
      <button
        class="tab"
        v-for="(tab, tabID) in tabs"
        :key="`${tab?.Name}-${tabID}`"
        :data-tab="`tab-${tabID}`"
        :class="[{'is-active': activeTabId === tabID}]"
        @click="activatedTab(tab, tabID)"
        type="button"
      >{{tab?.Name || tab?.id}}
      </button>
    </div>

    <div
      class="panels"
      v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 600}"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <slot :activeTab="activeTab"></slot>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
    },
    groupId: {
      type: Number,
    },
    container: {
      type: Boolean,
      default: false,
    },
    initActive: {
      type: Number,
      default: 0,
    },
  },
  emits: ['tab-switch', 'tab-init'],
  data() {
    return {
      activeTab: this?.tabs?.[this.initActive],
      activeTabId: this.initActive,
    };
  },
  methods: {
    activatedTab(tab, tabId) {
      this.activeTab = tab;
      this.activeTabId = tabId;
      this.$emit('tab-switch', tab, this.groupId);
    },
  },
  mounted() {
    this.$emit('tab-init', this.activeTab, this.groupId);
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  .wrapper {
    width: 100%;
  }

  .navigation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    @include wRule(
      margin-bottom,
      (
        xl: 45,
        lg: 45,
        md: 30,
        sm: 30,
      )
    );

    @include rule(
      align-items,
      (
        xl: flex-end,
        lg: center,
      )
    );
  }

  .panels {
    display: block;
  }

  .tab {
    display: inline-block;
    position: relative;
    font-weight: 400;
    color: color(basic-light);
    text-transform: uppercase;
    letter-spacing: -0.02em;

    @include wRule(
      margin-bottom,
      (
        xl: 10,
        lg: 10,
        md: 10,
        sm: 10,
      )
    );

    @include rule(
      line-height,
      (
        xl: 1.3,
        lg: 2.2,
        md: 1.3,
        sm: 1.8,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 22,
        md: 20,
        sm: 34,
      )
    );

    &::after {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: 0.04em;
      width: 100%;
      background-color: color(main);
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;

      @include wRule(
        height,
        (
          xl: 3,
          lg: 3,
          md: 2,
          sm: 4,
        )
      );
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 26,
          lg: 25,
          md: 22,
          sm: 38,
        )
      );
    }

    &.is-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

</style>
