<template>
  <PageSection
    class="screen lead-section"
    :bg="undefined"
  >
    <template #start>
      <div class="container">
        <div class="header">
          <h1
            class="title-lead"
            v-show="content?.Title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.Title}}</h1>
        </div>

        <div class="description">
          <p
            class="text-big"
            v-show="content?.Subtitle"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 400}"
            v-html="content?.Subtitle"
          ></p>
          <div class="links">
            <Link
              :tag="'router-link'"
              :to="'/about'"
              v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 900}"
            >
            <template #default>{{content?.ButtonText}}</template>
            <template #end>
              <Icon
                name="long-arrow"
                :style="'transition-delay: 900ms;'"
              />
            </template>
            </Link>
          </div>
        </div>

        <div class="contacts">

          <Link
            :tag="'a'"
            :href="`tel:${contacts?.Phone?.replace(/ /gi, '')}`"
            v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: 400}"
          >
          <template #start>
            <Icon :name="'phone'" />
          </template>
          <template #default>{{contacts?.Phone}}</template>
          </Link>

          <Link
            :tag="'a'"
            :href="`mailto:${contacts?.Email}`"
            v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: 600}"
          >
          <template #start>
            <Icon :name="'mail'" />
          </template>
          <template #default>{{contacts?.Email}}</template>
          </Link>

          <VButton
            :key="id"
            :tag="'a'"
            :href="getUrl(content?.PresentationFile?.url)"
            class="presentation"
            v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: 700}"
            target="_blank"
            v-show="false"
          >
            <template #start>
              <Icon name="pdf" />
            </template>
            <template #default>{{content?.DownloadPresentationButtonText}}</template>
          </VButton>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import Link from '../../../components/Link.vue';
import Icon from '../../../components/Icon.vue';
import VButton from '../../../components/VButton.vue';
import getUrl from '../../../tools/getUrl';

export default {
  name: 'LeadSection',
  components: {
    PageSection,
    Link,
    Icon,
    VButton,
  },
  props: {
    bg: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  computed: {
    contacts() {
      return this.$store.getters.getNavigationContacts;
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.lead-section {
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  color: color(basic-light);
  background-color: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    180deg,
    rgba(20, 24, 31, 0) 0%,
    rgba(20, 24, 31, 0.8) 100%
  );
  background-repeat: no-repeat;
  will-change: transform, z-index;
  backface-visibility: hidden;

  @include rule(
    padding-bottom,
    (
      xl: pxtovh(195, xl),
      lg: pxtovh(140, lg),
      md: pxtovh(140, md),
      sm: pxtovw(90, sm),
    )
  );

  @include screen("xxl", min) {
    padding-bottom: 195px;
  }

  @include screen("sm", max) {
    position: static;
    padding-top: pxtovw(182, sm);
    align-items: stretch;
  }

  ::v-deep(.container) {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    @include screen("sm", max) {
      flex-direction: column;
    }
  }

  .header {
    width: 100%;

    @include screen("sm", max) {
      order: 2;
      margin-top: pxtovw(40, sm);
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 40,
          lg: 38,
          md: 38,
          sm: 42,
        )
      );
    }
  }

  .description {
    display: block;
    box-sizing: border-box;
    flex: 1;

    @include wRule(
      padding-bottom,
      (
        xl: 100,
        lg: 30,
        md: 52,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      flex: none;
      width: 100%;
      order: 3;
    }
  }

  .text-big {
    display: block;
    width: 100%;

    @include wRule(
      max-width,
      (
        xl: 780,
        lg: 640,
        md: 480,
        sm: none,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 88,
          lg: pxtovh(96, lg),
          md: pxtovh(80, md),
          sm: 120,
        )
      );
    }
  }

  .contacts {
    align-self: end;
    flex-shrink: 0;
    line-height: 0;

    @include wRule(
      margin-right,
      (
        xl: -20,
        lg: 0,
      )
    );

    @include wRule(
      width,
      (
        xl: 366,
        lg: 314,
        md: 280,
        sm: 100%,
      )
    );

    @include screen("sm", max) {
      margin-bottom: auto;
    }

    .link {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 16,
            lg: 15,
            md: 6,
            sm: 18,
          )
        );
      }
    }

    .v-button {
      width: 100%;

      @include wRule(
        margin-top,
        (
          xl: 55,
          lg: 40,
          md: 40,
          sm: 40,
        )
      );
    }
  }
}
</style>
