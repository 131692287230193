<template>
  <div class="about-feature">
    <BgMedia :source="content?.Background" />
    <div class="content">
      <div
        class="text"
        v-html="content?.Text"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
      ></div>
    </div>
  </div>
</template>

<script>
import BgMedia from '../../../components/BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'AboutFeature',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.about-feature {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;

    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0) 0%,
        rgba(20, 24, 31, 0.8) 100%
      );
      z-index: 2;

      @include screen("sm", max) {
        background: linear-gradient(
          180deg,
          rgba(20, 24, 31, 0) 0%,
          rgba(20, 24, 31, 0.85) 100%
        );
      }
    }
  }

  &:first-child {
    @include wRule(
      padding-top,
      (
        xl: 60,
        lg: 60,
        md: 60,
        sm: 0,
      )
    );
  }

  .content {
    position: relative;
    z-index: 3;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    @include wRule(
      padding,
      (
        xl: 20 240 20 142,
        lg: 20 165 20 111,
        md: 20 125 20 86,
        sm: 20 45,
      )
    );
  }

  .text {
    line-height: 1.2;
  }

  &:nth-child(1) {
    .text {
      @include wRule(
        max-width,
        (
          xl: 340,
          lg: none,
        )
      );
    }
  }

  &:nth-child(2) {
    .text {
      @include wRule(
        max-width,
        (
          xl: 281,
          lg: none,
        )
      );
    }
  }

  &:nth-child(3) {
    .text {
      @include wRule(
        max-width,
        (
          xl: 427,
          lg: none,
          sm: 484,
        )
      );
    }
  }
}
</style>
