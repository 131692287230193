<template>
  <section
    class="page-section"
    :class="{'is-dark': theme}"
  >
    <transition
      name="fade"
      mode="in-out"
    >
      <BgMedia
        v-if="bg"
        :media="bg"
        :key="bg"
        :class="{ 'bg-fixed': bgFixed }"
      />
    </transition>
    <slot name="start"></slot>
    <slot></slot>
    <slot name="end"></slot>
  </section>
</template>

<script>
import BgMedia from './BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'PageSection',
  props: {
    bg: {
      type: Object,
    },
    theme: {
      type: Boolean,
      default: false,
    },
    bgFixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.page-section {
  position: relative;
  display: block;
  width: 100%;
  background-color: color(bg);
  box-sizing: border-box;

  &.is-dark {
    color: color(basic-light);
    background-color: color(dark);
  }

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      background-image: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0) 0%,
        rgba(20, 24, 31, 0.8) 100%
      );
      background-repeat: no-repeat;
      transition: opacity $ease-main;
      z-index: 1;
    }
  }

  ::v-deep(.container),
  ::v-deep(.container-wide) {
    position: relative;
    z-index: 2;
  }

  &.screen {
    min-height: calc(var(--vh, 1vh) * 100);

    @include screen("sm", max) {
      transition: min-height 100s ease;
    }
  }

  &.centered {
    ::v-deep(.container) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include screen("sm", max) {
        align-items: flex-start;
      }
    }
  }
}
</style>
