export default {
  mounted(el, binding) {
    const element = el;

    el.classList.add('is-inview-animate');
    el.classList.add(`is-inview-animation-${binding.value.name}`);
    element.style.transitionDuration = `${binding.value.duration || 300}ms`;
    element.style.transitionDelay = `${binding.value.delay || 0}ms`;

    const repeat = binding.arg === 'repeat';

    function toggleAnimation(entries) {
      entries.forEach((entry) => {
        const elem = entry.target;
        if (entry.isIntersecting) {
          elem.classList.add('is-inview-animation-active');
          elem.setAttribute('data-onscreen', 'visible');
        } else if (repeat) {
          elem.classList.remove('is-inview-animation-active');
          elem.setAttribute('data-onscreen', 'hidden');
        } else {
          elem.setAttribute('data-onscreen', 'hidden');
        }
      });
    }

    const observer = new IntersectionObserver(toggleAnimation, {
      threshold: [0, 0.5, 1],
    });

    element.observer = observer;

    observer.observe(el);
  },
  updated(el, binding) {
    const element = el;
    const isVisible = el.getAttribute('data-onscreen');

    if (binding.arg === 'repeat' || !el.classList.contains('is-inview-animation-active')) {
      el.classList.remove(`is-inview-animation-${binding.oldValue.name}`);
      el.classList.add(`is-inview-animation-${binding.value.name}`);

      if (isVisible === 'visible') {
        el.classList.add('is-inview-animation-active');
      } else {
        el.classList.remove('is-inview-animation-active');
      }

      element.style.transitionDuration = `${binding.value.duration || 300}ms`;
      element.style.transitionDelay = `${binding.value.delay || 0}ms`;
    }
  },
  unmounted(el) {
    el.observer.unobserve(el);
  },
};
