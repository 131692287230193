import axios from 'axios';
import store from '../store/index';

export default function api(path, locale, request) {
  console.log(`${process.env.VUE_APP_BACKEND_HOST}/${path}${locale ? `?_locale=${store.state.lang}` : ''}${request ? `${locale ? '&' : '?'}${request}` : ''}`);

  return axios.get(`${process.env.VUE_APP_BACKEND_HOST}/${path}${locale ? `?_locale=${store.state.lang}` : ''}${request ? `${locale ? '&' : '?'}${request}` : ''}`).then((response) => {
    console.log(response);
    return response;
  });
}
