export default function navigationSticking(instance) {
  const inst = instance;
  const navigation = inst.$el;
  const parent = navigation.parentElement;
  const breadcrumbs = document.querySelector('.breadcrumbs');

  function handlerScroll() {
    const bp = inst.$store.getters.getBreakpoint;
    if (bp !== 'sm') {
      const navSizes = navigation.getBoundingClientRect();
      const parentSizes = parent.getBoundingClientRect();
      let topShift = 0;
      if (breadcrumbs) {
        const breadHeight = breadcrumbs.offsetHeight;
        const breadBM = parseFloat(window.getComputedStyle(breadcrumbs, null).getPropertyValue('margin-bottom'));
        topShift = breadHeight + breadBM;
      }
      const top = parentSizes.top + topShift;

      inst.isSticky = (top <= 100 && navSizes.bottom < parentSizes.bottom);
      inst.isStopped = (parentSizes.bottom <= navSizes.height + 100);
    }
  }

  setTimeout(handlerScroll, 500);

  window.addEventListener('scroll', handlerScroll, { passive: true });
}
