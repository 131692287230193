<template>
  <PageSection
    :bg="false"
    class="screen instrument-section"
    :theme="true"
  >
    <template #start>
      <teleport to="#popups" :disabled="!isToolTeleportActive">
        <div
          class="instrument-cards"
          :class="{'is-popup': isToolTeleportActive}"
        >
          <Close @click.prevent="closePopup" />
          <swiper
            :speed="500"
            :effect="'fade'"
            :fade-effect="{crossFade: true}"
            @active-index-change="instrumentChange"
            @swiper="instrumentsSwiper = $event"
          >
            <swiper-slide
              v-for="(slide, slideKey) in content?.instruments"
              :key="`card-${slideKey}`"
              v-slot="{ isActive }"
            >
              <div class="instrument-card">
                <div class="container">
                  <div class="content">
                    <div
                      class="subtitle animation"
                      :class="[{'is-animated': isActive}]"
                    >{{ slide?.Name }}</div>
                    <div
                      class="description animation"
                      :class="[{'is-animated': isActive}]"
                      v-html="markdownToHTML(slide?.Description || '')"
                    ></div>
                  </div>
                </div>
                <div class="background">
                  <img
                    :src="getUrl(slide?.Image.url)"
                    :alt="slide?.Name"
                  >
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </teleport>
    </template>
    <template #default>
      <div class="container section-content">
        <div class="wrapper">
          <h2
            class="title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
          >{{content?.Title}}</h2>
          <div class="controls" v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 600}">
            <SliderDots
              :swiper="swiperInstance"
              :dots="slides"
            />
            <SliderArrows :swiper="swiperInstance" class="theme-white"/>
          </div>
          <div
            class="slider"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 800}"
          >
            <Swiper
              @swiper="swiperInstance = $event"
              :space-between="50"
              :key="multiplier"
            >
              <SwiperSlide
                v-for="(slide, slideKey) in slides"
                :key="`slide-${slideKey}`"
              >
                <div class="thumbs-grid">
                  <div
                    class="thumb-wrapper"
                    v-for="(thumb, thumbKey) in slide"
                    :key="`thumb-${thumbKey}`"
                    @click.prevent="changeCard(slideKey, thumbKey)"
                  >
                    <div
                      class="thumb-card"
                      :class="{'is-active': currentTool === ( slideKey * multiplier + thumbKey)}"
                    >
                      <div class="thumb-image">
                        <img
                          :src="getUrl(thumb?.Image.url)"
                          :alt="thumb?.Name"
                        >
                      </div>
                      <div class="thumb-title">{{ thumb?.Name }}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div
          class="wrapper-content"
          v-if="breakpoint !== 'sm'"
          :key="currentItem?.Name"
        >
          <div class="content">
            <div
              class="subtitle"
              v-inview-animate:repeat="{name: 'fade-down', duration: 300, delay: 100}"
            >{{ currentItem?.Name }}</div>
            <div
              class="description"
              v-inview-animate:repeat="{name: 'fade-down', duration: 300, delay: 200}"
              v-html="markdownToHTML(currentItem?.Description || '')"
            />
          </div>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { EffectFade } from 'swiper/core';
import SliderArrows from '@/components/SliderArrows.vue';
import SliderDots from '@/components/SliderDots.vue';
import Close from '@/components/Close.vue';
import { animationMixin } from '../../../components/mixins/animation';
import { freeze, unfreeze } from '@/tools/blockScroll';
import 'swiper/components/effect-fade/effect-fade.scss';
import getUrl from '@/tools/getUrl';

SwiperCore.use([EffectFade]);

// Markdown
const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'InstrumentSection',
  mixins: [animationMixin],
  components: {
    PageSection, SliderArrows, SliderDots, Swiper, SwiperSlide, Close,
  },
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      currentTool: 0,
      isToolTeleportActive: false,
      swiperInstance: null,
      multiplier: 4,
      instrumentsSwiper: null,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    slides() {
      if (!this.content?.instruments) return [];
      const size = this.multiplier;
      return (
        Array(Math.ceil(this.content?.instruments?.length / size) ?? size)
          .fill(0)
          .map((x, i) => this.content?.instruments?.slice(i * size, i * size + size)) ?? []
      );
    },
    currentItem() {
      return this.content?.instruments ? this.content?.instruments[this.currentTool] : {};
    },
  },
  methods: {
    getUrl,
    changeCard(slideKey, thumbKey) {
      this.currentTool = this.multiplier * slideKey + thumbKey;
      this.instrumentsSwiper.slideTo(this.currentTool);
      if (this.breakpoint === 'sm') {
        this.isToolTeleportActive = true;
        freeze();
      }
    },
    onSwipe(dir) {
      if (this.breakpoint === 'sm') {
        const totalCount = this.content?.instruments.length - 1;
        const currentItem = this.currentTool;
        let newItem = currentItem;
        if (dir === 'left') {
          newItem = currentItem === totalCount ? currentItem : currentItem + 1;
        } else {
          newItem = currentItem === 0 ? 0 : currentItem - 1;
        }
        this.currentTool = newItem;
      }
    },
    markdownToHTML(content) {
      return content ? md.render(content) : '';
    },
    closePopup() {
      this.isToolTeleportActive = !this.isToolTeleportActive;
      unfreeze();
    },
    instrumentChange(sw) {
      if (this.breakpoint === 'sm') {
        const active = sw.realIndex;
        this.currentTool = this.currentTool === active ? this.currentTool : sw.activeIndex;
      }
    },
  },
  mounted() {
    this.multiplier = 6;
    if (window.matchMedia('(max-width: 1939px)').matches) {
      this.multiplier = 4;
    }
    window.addEventListener('resize', () => {
      this.multiplier = 6;
      if (window.matchMedia('(max-width: 1939px)').matches) {
        this.multiplier = 4;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.instrument-section {
  display: flex;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  ::v-deep(.swiper-wrapper) {
    align-items: stretch;
  }

  &::before {
    @include pseudo;

    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(11px);
    z-index: 2;

    @include wRule(
      width,
      (
        xl: 743,
        lg: 577,
        md: 432,
        sm: 100%,
      )
    );

    @include screen("xxl", min) {
      width: calc(12.5vw + 512px);
    }
  }

  .subtitle {
    font-weight: 700;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 45,
      )
    );

    @include wRule(
      max-width,
      (
        xl: 380,
        lg: 340,
        md: 300,
      )
    );

    @include screen("sm", max) {
      max-width: none;
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
    }
  }

  .description {
    line-height: 1.6;

    @include wRule(
      max-width,
      (
        xl: 380,
        lg: 340,
        md: 300,
      )
    );

    @include screen("sm", max) {
      max-width: none;
    }

    ::v-deep(ul) {
      display: block;

      li {
        position: relative;
        display: block;
        box-sizing: border-box;

        @include wRule(
          padding-left,
          (
            xl: 72,
            lg: 54,
            md: 38,
            sm: 75,
          )
        );

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          background: currentColor;
          margin-top: 0.7em;

          @include wRule(
            width,
            (
              xl: 20,
              lg: 15,
              md: 11,
              sm: 20,
            )
          );

          @include wRule(
            height,
            (
              xl: 3,
              lg: 2,
              md: 2,
              sm: 3,
            )
          );
        }
      }
    }

    ::v-deep(> :not(:last-child)) {
      @include wRule(
        margin-bottom,
        (
          xl: 16,
          lg: 15,
          md: 12,
          sm: 24,
        )
      );
    }

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 30,
          lg: 17,
          md: 21,
          sm: 54,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.2s, transform 0.3s ease 0.2s;
    }
  }
}

/*
Main content
 */
.section-content {
  position: relative;
  display: flex;
  justify-content: flex-start;
  z-index: 2;
  box-sizing: border-box;

  @include wRule(
    padding-top,
    (
      // xl: 244,
      // lg: 180,
      // md: 163,
      // sm: 150,
      xl: 100,
      lg: 80,
      md: 100,
      sm: 150
    )
  );
  @include wRule(
    padding-bottom,
    (
      xl: 100,
      lg: 80,
      md: 100,
      sm: 150,
    )
  );

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include wRule(
      margin-bottom,
      (
        xl: 38,
        lg: 30,
        md: 33,
        sm: 110,
      )
    );
  }

  .slider {
    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
    }
  }

  .thumbs-grid {
    display: flex;
    flex-wrap: wrap;

    @include wRule(
      margin,
      (
        xl: -20 -26,
        lg: -20,
        md: -22 -13,
        sm: -30 -25,
      )
    );
  }

  .thumb-wrapper {
    box-sizing: border-box;
    width: 50%;

    @include wRule(
      padding,
      (
        xl: 20 26,
        lg: 20,
        md: 22 13,
        sm: 30 25,
      )
    );
  }

  .title {
    font-weight: 700;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 65,
        lg: 50,
        md: 45,
        sm: 65,
      )
    );
    @include wRule(
      margin-bottom,
      (
        xl: 35,
        lg: 14,
        md: 48,
        sm: 62,
      )
    );
  }

  .wrapper {
    @include wRule(
      width,
      (
        xl: 400,
        lg: 310,
        md: 270,
        sm: 100%,
      )
    );
  }

  .wrapper-content {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;

    @include rule(
      display,
      (
        xl: flex,
        sm: none,
      )
    );

    @include wRule(
      padding-left,
      (
        xl: 213,
        lg: 182,
        md: 100,
        sm: 0,
      )
    );
  }

  .thumb-card {
    .thumb-image {
      position: relative;
      display: block;
      overflow: hidden;
      cursor: pointer;
      box-sizing: border-box;

      @include wRule(
        max-width,
        (
          xl: 170,
          lg: 127,
          md: 108,
          sm: 249,
        )
      );

      @include wRule(
        height,
        (
          xl: 113,
          lg: 84,
          md: 70,
          sm: 164,
        )
      );

      &::before {
        @include pseudo;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        border: 3px solid color(main);
        transition: opacity $ease-main;
        box-sizing: border-box;
      }

      img {
        @include fill-img;

        z-index: 0;
      }
    }

    .thumb-title {
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 100%;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 18,
          md: 16,
          sm: 30,
        )
      );
      @include wRule(
        margin-top,
        (
          xl: 21,
          lg: 15,
          md: 13,
          sm: 32,
        )
      );
    }

    @media (hover: hover) {
      &:hover {
        .thumb-image {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    &.is-active {
      .thumb-image {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

/*
Background and popup cards
 */
.instrument-card {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;

  &.fade-enter-active {
    @include screen("sm", min) {
      transition: all 0.4s ease-out;
    }
  }

  &.fade-leave-active {
    @include screen("sm", min) {
      transition: all 0.3s ease;
    }
  }

  &.fade-enter-from,
  &.fade-leave-to {
    @include screen("sm", min) {
      opacity: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include wRule(
      min-height,
      (
        xl: 716,
        lg: 540,
        md: 512,
        sm: 0,
      )
    );

    .is-popup & {
      @include screen("sm", max) {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    @include rule(
      display,
      (
        xl: none,
        sm: block,
      )
    );

    @include wRule(
      padding-left,
      (
        xl: 613,
        lg: 492,
        md: 370,
        sm: 0,
      )
    );

    @include wRule(
      padding,
      (
        sm: 106 40 106 0,
      )
    );

    @include screen("sm", max) {
      opacity: 0;
      transition: opacity $ease-main;
    }

    .is-popup & {
      @include screen("sm", max) {
        opacity: 1;
        max-height: 100%;
        overflow: auto;
      }
    }
  }

  .background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    max-width: 100vw;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0) 0%,
        rgba(20, 24, 31, 0.85) 100%
      );
    }

    img {
      @include fill-img;
    }
  }
}

.instrument-cards {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  overflow: hidden;

  ::v-deep(.swiper-container) {
    width: 100%;
    height: 100%;
  }

  ::v-deep(.swiper-wrapper) {
    transition-timing-function: ease;
  }

  ::v-deep(.close) {
    visibility: hidden;
    position: absolute;
    right: pxtovw(34, sm);
    top: pxtovw(20, sm);
    z-index: 3;
    background-color: transparent;
  }

  .subtitle {
    font-weight: 700;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 45,
      )
    );

    @include wRule(
      max-width,
      (
        xl: 380,
        lg: 340,
        md: 300,
      )
    );

    @include screen("sm", max) {
      max-width: none;
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
    }
  }

  .description {
    line-height: 1.6;

    @include wRule(
      max-width,
      (
        xl: 380,
        lg: 340,
        md: 300,
      )
    );

    @include screen("sm", max) {
      max-width: none;
    }

    ::v-deep(ul) {
      display: block;

      li {
        position: relative;
        display: block;
        box-sizing: border-box;

        @include wRule(
          padding-left,
          (
            xl: 72,
            lg: 54,
            md: 38,
            sm: 75,
          )
        );

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          background: currentColor;
          margin-top: 0.7em;

          @include wRule(
            width,
            (
              xl: 20,
              lg: 15,
              md: 11,
              sm: 20,
            )
          );

          @include wRule(
            height,
            (
              xl: 3,
              lg: 2,
              md: 2,
              sm: 3,
            )
          );
        }
      }
    }

    ::v-deep(> :not(:last-child)) {
      @include wRule(
        margin-bottom,
        (
          xl: 16,
          lg: 15,
          md: 12,
          sm: 24,
        )
      );
    }

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 30,
          lg: 17,
          md: 21,
          sm: 54,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.2s, transform 0.3s ease 0.2s;
    }
  }

  &.is-popup {
    position: fixed;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    overflow: hidden;
    z-index: 100;
    color: color(bg);
    background-color: rgba(#14181f, 0.8);
    animation: fadein 0.4s ease;
    touch-action: pan-x;

    ::v-deep(.close) {
      visibility: visible;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    height: auto;
    transition-property: transform, opacity;
    transition-timing-function: ease;

    &.swiper-slide-active {
      pointer-events: auto;
    }
  }
}
</style>
