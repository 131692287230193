<template>
  <div class="page-container">
    <section class="contacts-lead">
      <Breadcrumbs />
      <ContactsMap :content="mapOffices" />
      <Offices :content="listOffices" />
      <TeamSection :content="team" v-if="false" />
    </section>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import ContactsMap from './components/ContactsMap.vue';
import Offices from './components/Offices.vue';
import TeamSection from './components/TeamSection/TeamSection.vue';
import api from '../../tools/api';

export default {
  name: 'Contacts',
  components: {
    Breadcrumbs,
    ContactsMap,
    Offices,
    TeamSection,
  },
  data() {
    return {
      pageContent: [],
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    mapOffices() {
      return {
        linkName: this.pageContent?.OfficeLinkText,
        offices: this.pageContent?.Offices.filter((el) => el?.Map),
      };
    },
    listOffices() {
      return {
        linkName: this.pageContent?.OfficeLinkText,
        offices: this.pageContent?.Offices.filter((el) => el?.List),
      };
    },
    team() {
      const compare = (a, b) => parseInt(a?.Order, 10) - parseInt(b?.Order, 10);
      const list = this.pageContent?.Team;
      return {
        title: this.pageContent?.TeamSectionName,
        team: list.sort(compare),
      };
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('page-contacts-content', true);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
  },
  created() {
    this.getPageContent();
  },
  watch: {
    lang() {
      this.getPageContent();
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-lead {
  @include wRule(
    padding-top,
    (
      xl: 288,
      lg: 217,
      md: 172,
      sm: 170,
    )
  );

  @include wRule(
    padding-bottom,
    (
      xl: 54,
      lg: 135,
      md: 82,
      sm: 100,
    )
  );

  .offices {
    @include wRule(
    padding-bottom,
    (
      xl: 65,
      lg: 25,
      md: 25,
      sm: 25,
    )
  );

    @include wRule(
      margin-top,
      (
        xl: 140,
        lg: 80,
        md: 47,
        sm: 100,
      )
    );
  }
}
</style>
