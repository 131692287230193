<template>
  <PageSection
    class="screen about-section"
    :theme="true"
    :bg="undefined"
  >
    <Breadcrumbs />
    <div class="grid">
      <div class="column-left">
        <AboutLead :content="content?.[0]" />
      </div>
      <div class="column-right">
        <template
          v-for="(feature, featureId) in getFeatures"
          :key="featureId"
        >
          <AboutFeature :content="feature" />
        </template>
      </div>
    </div>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import AboutLead from './AboutLead.vue';
import AboutFeature from './AboutFeature.vue';
import Breadcrumbs from '../../../components/Breadcrumbs.vue';

export default {
  components: {
    PageSection,
    AboutLead,
    AboutFeature,
    Breadcrumbs,
  },
  name: 'AboutSection',
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    getFeatures() {
      return this?.content?.filter((el, i) => i !== 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.about-section {
  position: relative;
  display: flex;
  will-change: transform, z-index;
  backface-visibility: hidden;

  .grid {
    display: flex;
    width: 100%;

    @include screen("sm", max) {
      display: block;
    }
  }

  .column-left {
    display: flex;

    @include rule(
      width,
      (
        xl: 55.7%,
        md: 55.57%,
        sm: 100%,
      )
    );

    @include screen("sm", max) {
      min-height: calc(var(--vh, 1vh) * 100);
      transition: min-height 100s ease;
    }
  }

  .column-right {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    flex: 1;

    @include screen("sm", max) {
      flex: none;
      width: 100%;
      min-height: calc(var(--vh, 1vh) * 100);
      transition: min-height 100s ease;
    }
  }
}
</style>
