<template>
  <div class="about-lead">
    <BgMedia :source="content?.Background" />
    <div class="content">
      <h2
        class="title"
        v-html="content?.Text"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
      ></h2>
    </div>
  </div>
</template>

<script>
import BgMedia from '../../../components/BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'AboutLead',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.about-lead {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;

  .bg-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;

    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0) 0%,
        rgba(20, 24, 31, 0.8) 100%
      );
      z-index: 2;

      @include screen("sm", max) {
        background: linear-gradient(
          180deg,
          rgba(20, 24, 31, 0) 0%,
          rgba(20, 24, 31, 0.85) 100%
        );
      }
    }
  }

  .content {
    position: relative;
    z-index: 3;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;

    @include wRule(
      padding,
      (
        xl: 220 142 170 240,
        lg: 160 78 100 165,
        md: 160 50 100 123,
        sm: 120 45 80,
      )
    );
  }
}
</style>
