<template>
  <PageHeader
    @open-popup="openPopup"
    :smTheme="theme"
    :isFooterReached="sliderReachFooter"
  />
  <main class="page">
    <router-view v-slot="{ Component, route }">
      <transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="Component"
          @open-popup="openPopup"
          :key="route.name"
          @page-slider-reach-footer="pageSliderReachFooter"
          @page-slider-go-away-footer="pageSliderGoAwayFromFooter"
        />
      </transition>
    </router-view>
  </main>
  <teleport to="#popups">
    <transition
      name="fade"
      mode="out-in"
    >
      <Popup
        v-if="popupActive"
        :isShown="popupActive"
        @modal-closed="popupActive = false"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <component
            :is="currentPopup"
            :key="currentPopup"
            :isResult="isResult"
            :presetData="presetData"
            @change-content="onChangePopup"
            @modal-closed="popupActive = false"
          />
        </transition>
      </Popup>
    </transition>
  </teleport>
  <PageFooter
    v-if="!noFooter"
    @open-popup="openPopup"
  />
</template>

<script>
import lazySizes from 'lazysizes';
import PageFooter from './components/PageFooter.vue';
import PageHeader from './components/PageHeader/PageHeader.vue';
import Popup from './components/Popup.vue';
import breakpointWatcher from './tools/breakpointsWatcher';
import Alliance from './components/Alliance/Alliance.vue';
import CertificatesPopup from './components/CertificatesPopup.vue';
import FleetPopup from './components/FleetPopup/FleetPopup.vue';
import vhFix from './tools/vhFix';
import api from './tools/api';

lazySizes.cfg.loadHidden = false;

export default {
  name: 'App',
  components: {
    PageHeader,
    Popup,
    PageFooter,
    Alliance,
    CertificatesPopup,
    FleetPopup,
  },
  data() {
    return {
      popupActive: false,
      theme: false,
      currentPopup: 'Alliance',
      presetData: null,
      noFooter: true,
      iconsLoading: true,
      labelsLoading: true,
      navLoading: true,
      pageLoading: true,
      allianceLoading: false,
      sliderReachFooter: false,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    setLanguage() {
      // const lang = localStorage.getItem('lang') ?? 'ru';
      const lang = 'ru';
      localStorage.setItem('lang', lang);
      this.$store.commit('setLang', lang);
    },
    openPopup(data) {
      this.onChangePopup(data);
      this.popupActive = true;
    },
    onChangePopup(data) {
      this.currentPopup = data;
    },
    async getAlliance() {
      const results = await api('alliance', true);
      this.allianceLoading = !results.statusText === 'ok';
      const alliance = results.data;
      this.$store.commit('setAlliance', alliance);
    },
    async getCertificates() {
      const results = await api('page-about-content', true);
      this.certLoading = !results.statusText === 'ok';
      const certs = results.data?.SectionCertificates?.Certificates;
      this.$store.commit('setCertificates', certs);
    },
    async getNavigation() {
      const results = await api('navigation', true);
      this.navLoading = !results.statusText === 'ok';
      const navigation = results.data;
      const themes = await api('navigation-colors', false);
      const themesData = themes.data;
      const contacts = await api('basic-contacts', false);
      const contactsData = contacts.data;
      const socials = await api('socials', false);
      const socialData = socials.data;
      this.$store.commit('setNavigation', navigation);
      this.$store.commit('setNavigationColors', themesData);
      this.$store.commit('setNavigationContacts', contactsData);
      this.$store.commit('setSocials', socialData);

      document.title = `${this.$store.getters.getNavigationData?.[
        `${this.$route.name}`
      ]?.title || this.$route.name} | СКАЙПРО Хеликоптерс`;
    },
    pageSliderReachFooter() {
      const route = this.$route.name;
      if ((route === 'About' || route === 'Main') && this.breakpoint !== 'sm') {
        this.sliderReachFooter = true;
      }
    },
    pageSliderGoAwayFromFooter() {
      const route = this.$route.name;
      if ((route === 'About' || route === 'Main') && this.breakpoint !== 'sm') {
        this.sliderReachFooter = false;
      }
    },
  },
  created() {
    this.setLanguage();
    this.getNavigation();
    this.getAlliance();
    this.getCertificates();
  },
  mounted() {
    breakpointWatcher(this);
    vhFix();
  },
  watch: {
    currentLang(lang) {
      localStorage.setItem('lang', lang);
      this.getNavigation();
      this.getAlliance();
      this.getCertificates();
    },
    breakpoint(value) {
      if (this.$route.name === 'Main') {
        this.noFooter = value !== 'sm';
      }
    },
    $route(route) {
      this.noFooter = route.name === 'Main' || route.name === 'About';
      this.sliderReachFooter = false;
    },
  },
};
</script>

<style lang="scss" src="./styles/index.scss"></style>
