<template>
  <div
    class="person-short-card"
    :class="{ 'cursor-pointer': data.Description }"
    @click.prevent="showFullInfo"
  >
    <div class="image-wrapper">
      <img
        v-if="data?.Photo?.url"
        :src="getUrl(data?.Photo?.formats?.thumbnail?.url)"
        :data-src="getUrl(data?.Photo?.url
        )"
        :alt="data?.Name"
        class="image lazyload"
      >
    </div>
    <div class="content">
      <h3 class="name">{{ data?.Name }}</h3>
      <h4 class="position">{{ data?.Position }}</h4>
      <div class="contacts">
        <p v-if="data?.Phone">
          <a
            @click.stop
            :href="`tel:${data?.Phone.replace(/ /gi, '').replace('(', '').replace(')', '').replace(/-/gi, '')}`"
          >{{ data?.Phone }}</a>
        </p>
        <p v-if="data?.Email">
          <a
            @click.stop
            :href="`mailto:${ data?.Email }`"
            target="_blank"
          >{{ data?.Email  }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import getUrl from '../../../../../tools/getUrl';

export default {
  name: 'PersonShortCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['showFullInfo'],
  methods: {
    getUrl,
    showFullInfo() {
      if (this.data.Description) {
        this.$emit('showFullInfo', this.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.person-short-card {
  user-select: none;

  &.cursor-pointer {
    cursor: pointer;
    pointer-events: auto;

    &:hover {
      .image {
        image-rendering: pixelated;
        transform: scale(1.1);
      }

      .name {
        background-position: 0% 1.2em;

        // &::before {
        //   transform: scaleX(1);
        // }
      }
    }
  }

  .image-wrapper {
    width: 100%;
    position: relative;
    padding-bottom: (397/345) * 100%;
    overflow: hidden;
  }

  .image {
    @include fill-img;

    bottom: auto;
    transition: transform 0.4s ease;
  }

  .content {
    @include wRule(
      margin-top,
      (
        xl: 50,
        lg: 34,
        md: 40,
        sm: 58,
      )
    );
  }

  .name {
    position: relative;
    display: inline;
    line-height: 1.3;
    font-weight: 700;
    transition: background 0.4s ease;
    // white-space: nowrap;
    background-image: linear-gradient(
    to right,
    currentColor 0%,
    currentColor 50%,
    transparent 50%,
    transparent 100%
    );
    background-size: 200% 0.1em;
    background-position: 100% 1.2em;
    background-repeat: no-repeat;

    // &::before {
    //   @include pseudo;

    //   font-size: inherit;
    //   left: 0;
    //   bottom: 0.2em;
    //   width: 100%;
    //   height: 0.1em;
    //   background-color: currentColor;
    //   transition: transform 0.4s ease;
    //   transform: scaleX(0);
    //   transform-origin: 0 50%;
    // }

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );
  }

  .position {
    line-height: 1.5;
    color: color(gray);
    min-height: 3em;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 14,
        sm: 20,
      )
    );

    @include wRule(
      margin-top,
      (
        xl: 4,
        lg: 0,
        sm: 6,
      )
    );
  }

  .contacts {
    line-height: 1.4;

    @include wRule(
      margin-top,
      (
        xl: 1,
        lg: 12,
        md: 0,
        sm: 6,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    a {
      position: relative;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }

      &:hover {

        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
}
</style>
