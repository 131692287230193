<template>
  <div class="page-container">
    <Breadcrumbs />
    <div class="bases">
      <template
        v-for="base in pageContent"
        :key="`${base?.name}`"
      >
        <BaseInfo
          :content="base"
          :class="{'base-info_half': !base.full_width, 'fullscreen' : base.full_width}"
        />
      </template>
    </div>
  </div>
</template>

<script>
import BaseInfo from './components/BaseInfo.vue';
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import api from '@/tools/api';

export default {
  name: 'Base',
  components: { BaseInfo, Breadcrumbs },
  data() {
    return {
      pageLoading: true,
      pageContent: [
        {
          title: 'Вертодром Горка',
          text:
            'Основной авторизованный центр обслуживания вертолетов семейства Leonardo Helicopters',
          background: {
            alternativeText: '',
            mime: 'image/jpeg',
            url: 'assets/bases/gorka.jpg',
          },
          backgroundMobile: {
            alternativeText: '',
            mime: 'image/jpeg',
            url: 'assets/bases/gorka-sm.jpg',
          },
        },
        {
          title: 'Пулково',
          text: 'Место базирования вертолёта МИ-8',
          background: {
            alternativeText: '',
            mime: 'image/jpeg',
            url: 'assets/bases/pulcovo.jpg',
          },
          backgroundMobile: {
            alternativeText: '',
            mime: 'image/jpeg',
            url: 'assets/bases/pulcovo-sm.jpg',
          },
        },
        {
          title: 'Геленджик',
          text:
            'Место базирования и обслуживания вертолетов семейства Leonardo Helicopters',
          background: {
            alternativeText: '',
            mime: 'image/jpeg',
            url: 'assets/bases/gelengic.jpg',
          },
          backgroundMobile: {
            alternativeText: '',
            mime: 'image/jpeg',
            url: 'assets/bases/gelengic-sm.jpg',
          },
        },
      ],
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('page-base-content', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data?.BasementPlaces;
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  created() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.bases {
  display: flex;
  flex-wrap: wrap;
}
</style>
