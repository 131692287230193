<template>
  <div
    class="container"
    v-observe-visibility="{callback: animation}"
  >
    <transition name="fade-down">
      <section
        class="alliance"
        v-show="inView"
      >
        <AllianceCard
          v-for="item in content?.Items"
          :data="item"
          :linkText="content?.LinkText"
          :key="item?.Title"
          :id="item?.Title.toLowerCase().replace(/ /gi, '-')"
          :inView="inView"
        ></AllianceCard>
      </section>
    </transition>
  </div>
</template>

<script>
import AllianceCard from './components/AllianceCard.vue';
import getUrl from '../../tools/getUrl';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'Alliance',
  mixins: [animationMixin],
  components: { AllianceCard },
  computed: {
    content() {
      return this.$store.getters.getAlliance;
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.alliance {
  display: grid;
  grid-template-columns: 35% repeat(2, 22.2%) 20.6%;
  width: 100%;
  border-top: 3px solid color(main);
  border-left: 3px solid color(main);
  box-sizing: border-box;

  @include wRule(
    border-width,
    (
      xl: 3,
      lg: 3,
      md: 3,
      sm: 3,
    )
  );

  @include rule(
    grid-template-rows,
    (
      xl: pxtovh(296, xl) pxtovh(293, xl),
      lg: pxtovh(211, lg) pxtovh(209, lg),
      md: pxtovh(157, md) pxtovh(157, md),
    )
  );

  @include screen("lg", max) {
    grid-template-columns: 35% repeat(2, 22.2%) 20.6%;
  }

  @include screen("sm", max) {
    display: block;
    border-left: none;
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
  }

  .alliance-card {
    border-right: 3px solid color(main);
    border-bottom: 3px solid color(main);

    @include wRule(
      border-width,
      (
        xl: 3,
        lg: 3,
        md: 3,
        sm: 3,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      height: pxtovw(113, sm);
      border-left: 3px solid color(main);
      border-width: pxtovw(3, sm);
    }

    &_helicopters {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    &_ground-services {
      grid-row: 1 / 2;
      grid-column: 2 / 4;
    }

    &_aviation {
      grid-row: 1 / 3;
      grid-column: 4 / 5;
    }

    &_group {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    &_technics {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }

    &_real-estate {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }
  }
}

.popup .container {
  @include wRule(
    padding-top,
    (
      sm: 105,
    )
  );
}

.popup .alliance {
  @include wRule(
    padding-bottom,
    (
      sm: 105,
    )
  );
}
</style>
