import { createStore } from 'vuex';

export default createStore({
  state: {
    lang: 'ru',
    currentBreakpoint: 'xl',
    windowWidth: 0,
    labels: [],
    icons: [],
    alliance: [],
    navigationData: {
    },
    navigation: {
    },
    navigationContacts: {
    },
    navigationColors: {
    },
    socials: [],
    certificates: [],
    fleetGroups: [],
    fleetModels: [],
    fleetParams: {},
    fleetMesures: {
      ru: {
        MaxSpeed: 'км/ч',
        MaxDistance: 'км',
        Passengers: '',
        TrunkVolume: 'м<sup>3</sup>',
      },
      en: {
        MaxSpeed: 'km/h',
        MaxDistance: 'km',
        Passengers: '',
        TrunkVolume: 'm<sup>3</sup>',
      },
    },
  },
  getters: {
    getLang(state) {
      return state.lang;
    },
    getNavigation(state) {
      return state.navigation;
    },
    getNavigationContacts(state) {
      return state.navigationContacts;
    },
    getBreakpoint(state) {
      return state.currentBreakpoint;
    },
    getContacts(state) {
      return state.contacts;
    },
    getNavigationData(state) {
      return state.navigationData;
    },
    getNavigationColors(state) {
      return state.navigationColors;
    },
    getAlliance(state) {
      return state.alliance;
    },
    getSocials(state) {
      return state.socials;
    },
    getCertificates(state) {
      return state.certificates;
    },
    getFleetGroups(state) {
      return state.fleetGroups;
    },
    getFleetGroupById(state) {
      return (id) => state.fleetGroups.filter((el) => el.id === id)[0];
    },
    getFleetModels(state) {
      return state.fleetModels;
    },
    getFleetModelById(state) {
      return (id) => state.fleetModels.filter((el) => el.id === id)[0];
    },
    getFleetParams(state) {
      return state.fleetParams;
    },
    getFleetMesures(state) {
      return (lang) => state.fleetMesures[lang];
    },
  },
  mutations: {
    setLang(state, value) {
      state.lang = value;
    },
    setBreakpoint(state, value) {
      state.currentBreakpoint = value;
    },
    setWindowWidth(state, value) {
      state.windowWidth = value;
    },
    setContacts(state, value) {
      state.contacts = value;
    },
    setNavigation(state, data) {
      state.navigation = data;
      state.navigationData = {
        Main: {
          title: data?.NamePageMain,
          href: '/',
          popup: false,
          tree: [],
        },
        About: {
          title: data?.NamePageAbout,
          href: '/about',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
        Services: {
          title: data?.NamePageServices,
          href: '/services',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
        Fleet: {
          title: data?.NamePageFleet,
          href: '/fleet',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
        Certificates: {
          title: data?.NamePageCertificates,
          popup: 'CertificatesPopup',
        },
        Base: {
          title: data?.NamePageBase,
          href: '/base',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
        Alliance: {
          title: data?.NamePageAlliance,
          popup: 'Alliance',
        },
        Contacts: {
          title: data?.NamePageContacts,
          href: '/contacts',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
        Policy: {
          title: data?.NamePagePolicy,
          href: '/privacy-policy',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
        Tools: {
          title: data?.NamePageInstruments,
          href: '/tools',
          popup: false,
          tree: [
            {
              path: '/',
              name: data?.NamePageMain,
            },
          ],
        },
      };
    },
    setNavigationContacts(state, value) {
      state.navigationContacts = value;
    },
    setNavigationColors(state, value) {
      state.navigationColors = value;
    },
    setAlliance(state, value) {
      state.alliance = value;
    },
    setSocials(state, value) {
      state.socials = value;
    },
    setCertificates(state, value) {
      state.certificates = value;
    },
    setFleetGroups(state, value) {
      state.fleetGroups = value;
    },
    setFleetModels(state, value) {
      state.fleetModels = value;
    },
    setFleetParams(state, value) {
      state.fleetParams = value;
    },
  },
  actions: {
  },
  modules: {
  },
});
