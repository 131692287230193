<template>
  <div class="feature-table">
    <template
      v-for="value, key in headers"
      :key="content?.[key]"
    >
      <div class="row">
        <div class="head">{{value}}</div>
        <div
          class="cell"
          v-html="(key === 'Passengers' ? `${content.PassengersMin ? content?.PassengersMin : ''}${content.PassengersMax && content.PassengersMin ? ' - ' : ''}${content.PassengersMax ? content.PassengersMax : ''}` : `${content[key] ? `${content[key]} ${mesures[key]}` : '-'}`)"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FeatureTable',
  props: {
    headers: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    mesures() {
      return this.$store.getters.getFleetMesures(this.lang);
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-table {
  display: block;
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid color(darkborder);
    box-sizing: border-box;
    width: 100%;
    letter-spacing: -0.02em;
    font-weight: 400;
    line-height: 1.2;
    padding: 0.3em 0;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    @include wRule(
      min-height,
      (
        xl: 42,
        lg: 38,
        md: 34,
        sm: 65,
      )
    );
  }

  .head {
    color: rgba(188, 188, 188, 0.8);
  }
}
</style>
