<template>
  <div class="certificates-popup">
    <CertificatesGallery
      :certificates="sectionCertificates"
      :initialSlide="clickedSlide"
    />
  </div>
</template>

<script>
import CertificatesGallery from './CertificatesSlider/components/CertificatesGallery.vue';

export default {
  components: { CertificatesGallery },
  name: 'CertificatesPopup',
  computed: {
    sectionCertificates() {
      return this.$store.getters.getCertificates;
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates-popup {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  @include wRule(
    padding,
    (
      xl: 40 0,
      lg: 40 0,
      md: 40 0,
      sm: 105 0,
    )
  );
}
</style>
