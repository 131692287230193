<template>
  <footer
    class="page-footer"
    v-observe-visibility="{callback: animation, once: true}"
  >
    <div class="container-wide">
      <transition name="fade-down">
        <div
          class="wrapper"
          v-show="inView"
        >
          <!-- Top -->
          <div class="
        top">
            <transition name="fade-down">
              <router-link
                to="/"
                class="logo"
                v-show="inView"
              >
                <Icon
                  :name="logo"
                  viewBox="0 0 366 52"
                />
              </router-link>
            </transition>
            <transition name="fade-down">
              <ul
                class="menu"
                v-show="inView"
              >
                <li
                  v-for="(link, linkId) in navigation"
                  :key="linkId"
                >
                  <Component
                    v-if="link"
                    :is="link.popup ? 'button' : 'router-link'"
                    :to="link.popup ? undefined : link?.href"
                    @click="link.popup ? popupShow(link?.popup) : null"
                  >
                    {{ link.title }}
                  </Component>
                </li>
              </ul>
            </transition>
            <!-- <transition name="fade-down">
              <p
                class="copyright"
                v-if="breakpoint === 'md' || breakpoint === 'sm'"
                v-show="inView"
              >{{ navigationData?.CopyrightShort }}</p>
            </transition> -->
            <!-- <transition name="fade-down">
              <div
                class="contacts"
                v-show="inView"
              >
                <a
                  :href="`mailto:${contacts?.Email}`"
                  target="_blank"
                  class="email"
                  v-if="!(breakpoint === 'xl' || breakpoint === 'lg')"
                >
                  {{contacts?.Email}}
                </a>
                <Socials />
              </div>
            </transition> -->
          </div>

          <!-- Bottom -->
          <transition name="fade-down">
            <div class="bottom">
              <div
                class="copyright-text"
                v-html="navigationData?.CopyrightLong"
              />
              <a
                :href="`mailto:${contacts?.Email}`"
                target="_blank"
                class="email"
              >
                {{contacts?.Email}}
              </a>
              <p
                class="copyright"
              >{{ navigationData?.CopyrightShort }}</p>
              <div class="made-in">{{ navigationData?.MadeInText }} <a
                  href="https://www.bsagency.design/"
                  target="_blank"
                >BS Agency</a></div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
  </footer>
</template>

<script>
import Icon from './Icon.vue';
// import Socials from './Socials.vue';
import { animationMixin } from './mixins/animation';

export default {
  name: 'PageFooter',
  components: {
    // Socials,
    Icon,
  },
  mixins: [animationMixin],
  emits: ['openPopup', 'footerUpdated'],
  data() {
    return {
      logo: 'logo-helicopters',
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    navData() {
      return this.$store.getters.getNavigationData;
    },
    navigation() {
      return [
        this.navData?.Main,
        this.navData?.About,
        this.navData?.Services,
        this.navData?.Fleet,
        this.navData?.Base,
        // this.navData?.Alliance,
        this.navData?.Tools,
        this.navData?.Contacts,
        this.navData?.Policy,
      ];
    },
    navigationData() {
      return this.$store.getters.getNavigation;
    },
    contacts() {
      return this.$store.getters.getNavigationContacts;
    },
  },
  methods: {
    popupShow(component) {
      this.$emit('open-popup', component);
    },
  },
  updated() {
    this.$emit('footer-updated');
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  position: relative;
  color: color(basic);
  letter-spacing: -0.02em;
  background-color: color(bg);
  transition: color $ease-main, background $ease-main;
  box-sizing: border-box;
  backface-visibility: hidden;

  @include wRule(
    padding,
    (
      xl: 52 0,
      lg: 48 0,
      md: 39 0,
      sm: 60 0,
    )
  );

  @include wRule(
    min-height,
    (
      xl: 255,
      lg: 273,
      md: 304,
      sm: 376,
    )
  );

  .wrapper {
    @include wRule(
      padding-top,
      (
        xl: 30,
        lg: 20,
        md: 24,
        sm: 42,
      )
    );

    border-top: 2px solid currentColor;

    @include wRule(
      border-top-width,
      (
        xl: 2,
        lg: 2,
        md: 2,
        sm: 3,
      )
    );

    &.fade-down-enter-active {
      transition-duration: 0.5s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  /*
  Top
   */
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include rule(
      justify-content,
      (
        xl: space-between,
        lg: flex-start,
      )
    );

    @include screen("lg", max) {
      flex-wrap: wrap;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include rule(position, (xl: relative, lg: absolute, md: relative));
    @include rule(top, (lg: 100%, md: auto));
    @include rule(right, (lg: 0%, md: auto));

    @include wRule(width, (
      lg: 100%,
      md: auto,
    ));

    @include rule(
      width,
      (
        sm: 100%,
      )
    );

    @include wRule(
      margin-left,
      (
        xl: 60,
        lg: 50,
        md: auto,
        sm: 0,
      )
    );
    @include wRule(
      margin-top,
      (
        lg: 30,
        md: 0,
        sm: 75,
      )
    );

    @include screen("sm", max) {
      justify-content: space-between;
    }

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.9s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .logo {
    display: block;
    transition: opacity $ease-main;
    flex-shrink: 0;

    @include wRule(
      width,
      (
        xl: 398,
        lg: 280,
        md: 328,
        sm: 353,
      )
    );

    @include wRule(
      height,
      (
        xl: 56,
        lg: 40,
        md: 47,
        sm: 50,
      )
    );

    @include wRule(
      margin-right,
      (
        xl: 40,
        lg: 42,
        md: auto,
        sm: 0,
      )
    );

    @include wRule(margin-bottom, (sm: 80));

    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }

    svg {
      display: block;
      width: 100%;
    }

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.6s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .menu {
    display: flex;
    align-items: center;

    // @include wRule(
    //   margin,
    //   (
    //     xl: 0 45,
    //     lg: 0 25,
    //     md: 0 20,
    //     sm: 0 20,
    //   )
    // );

    @include rule(
      display,
      (
        xl: flex,
        sm: grid,
        // md: none,
      )
    );

    @include rule(flex-grow, (xl: 1, lg: 0));

    @include wRule(margin-top, (md: 35, sm: 0));

    @include rule(order, (md: 1, sm: 0));

    @include wRule(column-gap, (sm: 40));
    @include wRule(row-gap, (sm: 48));

    @include screen('sm', max) {
      grid-template-columns: repeat(2, 1fr);
    }

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.7s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  li {
    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    font-weight: 700;
    line-height: 1;

    @include rule(line-height, (xl: 1, sm: 1.28));

    a,
    button {
      position: relative;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      color: inherit;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }

    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 47,
          lg: 36,
          md: 36,
          sm: 0,
        )
      );
    }
  }

  .copyright {
    white-space: nowrap;
    color: color(gray);

    // @include wRule(
    //   margin,
    //   (
    //     xl: 0 20,
    //     lg: 0 20,
    //     md: 0 10 0 20,
    //     sm: 0 0 0 20,
    //   )
    // );

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    // font-weight: 700;
    line-height: 1.28;

    @include wRule(
      margin-right,
      (
        xl: 53,
        lg: 0,
      )
    );

    @include wRule(margin-bottom, (sm: 40));
    @include rule(order, (lg: 1, sm: -1));
    @include rule(width, (lg: 50%, sm: auto));

    &.fade-down-enter-active {
      transition-duration: 0.5s;
      transition-delay: 0.8s;
    }

    &.fade-down-leave-active {
      transition-duration: 0.5s;
    }
  }

  .email {
    position: relative;
    color: color(gray);

    @include wRule(
      margin-right,
      (
        xl: 55,
        lg: auto,
        md: 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    line-height: 1.28;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }

  .socials {
    @include wRule(
      margin-left,
      (
        xl: 34,
        lg: auto,
        md: 41,
        sm: 0,
      )
    );

    ::v-deep(.social) {
      @include wRule(
        width,
        (
          xl: 35,
          lg: 28,
          md: 28,
          sm: 52,
        )
      );
      @include wRule(
        height,
        (
          xl: 35,
          lg: 28,
          md: 28,
          sm: 52,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-left,
          (
            xl: 36,
            lg: 27,
            md: 27,
            sm: 66,
          )
        );
      }
    }
  }

  /*
  Bottom
   */
  .bottom {
    justify-content: space-between;
    line-height: 1.28;
    letter-spacing: -0.02em;
    display: flex;

    @include rule(flex-direction, (sm: column));
    @include rule(flex-wrap, (lg: wrap));
    @include rule(justify-content, (xl: space-between, lg: flex-start));

    // @include rule(
    //   display,
    //   (
    //     sm: flex,
    //   ),
    //   min
    // );

    @include rule(
      align-items,
      (
        xl: flex-start,
        lg: flex-end,
        sm: flex-start,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 25,
      )
    );

    @include wRule(
      margin-top,
      (
        xl: 43,
        lg: 38,
        md: 38,
        sm: 77,
      )
    );
  }

  .copyright-text {
    color: color(gray);

    ::v-deep(br) {
      display: none;

      // &:nth-of-type(1) {
      //   @include screen("lg", max) {
      //     display: block;
      //   }
      // }

      @include screen("sm", max) {
        display: block;
      }
    }

    @include wRule(
      margin-right,
      (
        xl: 55,
        lg: 55,
        md: 30,
        sm: 0,
      )
    );

    @include wRule(
      margin-bottom,
      (
        sm: 40,
      )
    );
  }

  .made-in {
    font-weight: 700;
    white-space: nowrap;
    color: color(gray);

    @include rule(margin-left, (xl: auto, lg: 0));
    @include rule(text-align, (lg: right, sm: left));
    @include wRule(margin-top, (lg: 42, md: 38, sm: 86));
    @include rule(order, (lg: 2, sm: 1));
    @include rule(width, (lg: 50%, sm: auto));

    a {
      color: color(basic);
      transition: color $ease-main;

      &:hover {
        color: color(main);
      }
    }
  }

  &.is-dark {
    color: color(basic-light);
    background-color: color(dark);

    .copyright-text {
      @include rule(
        color,
        (
          xl: currentColor,
          lg: rgba(color(basic-light), 0.6),
        )
      );
    }

    .email {
      color: currentColor;
    }

    .made-in {
      @include rule(
        color,
        (
          xl: currentColor,
          sm: rgba(color(basic-light), 0.5),
        )
      );

      a {
        color: color(basic-light);

        &:hover {
          color: color(main);
        }
      }
    }
  }
}
</style>
