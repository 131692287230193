<template>
  <div class="bg-media">
    <template v-if="media">
      <picture v-if="media?.background?.mime.includes('image')">
        <source
          v-if="media?.backgroundMobile"
          :data-srcset="getUrl(media?.backgroundMobile?.url)"
          :type="media?.backgroundMobile?.mime"
          media="(max-width: 767px)"
        />
        <source
          v-if="media?.background"
          :data-srcset="getUrl(media?.background?.url)"
          :type="media?.background?.mime"
        />
        <img
          :src="(breakpoint==='sm' && media?.backgroundMobile) ? getUrl(media?.backgroundMobile?.formats?.thumbnail?.url) : getUrl(media?.background?.formats?.thumbnail?.url)"
          :data-src="getUrl(media?.background?.url)"
          :alt="media?.background?.alternativeText ?? '@@'"
          class="lazyload"
        >
      </picture>
      <video
        v-if="media?.background?.mime?.includes('video')"
        :src="getUrl(media?.background?.url)"
        loop
        autoplay
        muted
        playsinline
      >
        <source
          v-if="breakpoint==='sm'"
          media="(min-width: 767px)"
          :srcset="getUrl(media?.backgroundMobile?.url)"
        >
      </video>
    </template>
    <template v-if="source">
      <picture v-if="source?.mime.includes('image')">
        <source
          v-if="source"
          :data-srcset="getUrl(source?.url)"
          :type="source?.mime"
        />
        <img
          :src="getUrl(source?.formats?.thumbnail?.url)"
          :data-src="getUrl(source?.url)"
          :alt="source?.alternativeText ?? '@@'"
          class="lazyload"
        >
      </picture>
      <video
        v-if="source?.mime?.includes('video')"
        :src="getUrl(source?.url)"
        loop
        autoplay
        muted
        playsinline
      >
        <source
          v-if="breakpoint==='sm'"
          media="(min-width: 767px)"
          :srcset="getUrl(source?.url)"
        >
      </video>
    </template>

    <!-- <template v-if="media">
      <picture v-if="media?.background?.mime.includes('image')">
        <source
          v-if="media?.backgroundMobile"
          :data-srcset="require(`@/${media?.backgroundMobile?.url}`)"
          :type="media?.backgroundMobile?.mime"
          media="(max-width: 767px)"
        />
        <source
          v-if="media?.background"
          :data-srcset="require(`@/${media?.background?.url}`)"
          :type="media?.background?.mime"
        />
        <img
          :data-src="require(`@/${media?.background?.url}`)"
          :alt="media?.background?.alternativeText ?? '@@'"
          class="lazyload"
        >
      </picture>
      <video
        v-if="media?.background?.mime?.includes('video')"
        :src="require(`@/${media?.background?.url}`)"
        loop
        autoplay
        muted
        playsinline
      >
        <source
          v-if="breakpoint==='sm'"
          media="(min-width: 767px)"
          :srcset="require(`@/${media?.backgroundMobile?.url}`)"
        >
      </video>
    </template>
    <template v-if="source">
      <picture v-if="source?.mime.includes('image')">
        <source
          v-if="source"
          :data-srcset="require(`@/${source?.url}`)"
          :type="source?.mime"
        />
        <img
          :data-src="require(`@/${source?.url}`)"
          :alt="source?.alternativeText ?? '@@'"
          class="lazyload"
        >
      </picture>
      <video
        v-if="source?.mime?.includes('video')"
        :src="require(`@/${source?.url}`)"
        loop
        autoplay
        muted
        playsinline
      >
        <source
          v-if="breakpoint==='sm'"
          media="(min-width: 767px)"
          :srcset="require(`@/${source?.url}`)"
        >
      </video>
    </template> -->
  </div>
</template>

<script>
import getUrl from '../tools/getUrl';

export default {
  name: 'BgMedia',
  props: {
    media: {
      type: Object,
    },
    source: {
      type: Object,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss">
.bg-media {
  position: relative;
  display: block;
  overflow: hidden;

  picture {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;

    img {
      @include fill-img;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    @include fill-img;
  }

  &.bg-fixed {
    position: fixed;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    z-index: 0;
  }
}
</style>
