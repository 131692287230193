import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import ClickOutside from '@/tools/clickOutside';
import InviewAnimate from '@/tools/inviewAnimate';
import Vue3TouchEvents from 'vue3-touch-events';
import VTooltip from 'v-tooltip';
import { ObserveVisibility } from 'vue3-observe-visibility2';

createApp(App).use(store).use(router).use(Vue3TouchEvents)
  .directive('click-outside', ClickOutside)
  .directive('observe-visibility', ObserveVisibility)
  .directive('inview-animate', InviewAnimate)
  .use(VTooltip)
  .mount('#app');
