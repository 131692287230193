<template>
  <div
    class="office-popover"
    v-observe-visibility="animation"
  >
    <Close
      v-if="breakpoint==='sm'"
      @click.prevent="closePopover"
    />
    <transition name="fade-up">
      <div
        class="title"
        v-show="inView"
      >{{ content?.City }}</div>
    </transition>
    <transition name="fade-up">
      <div
        class="description"
        v-show="inView"
        v-if="content?.Description"
      >{{ content?.Description }}</div>
    </transition>
    <transition name="fade-up">
      <a
        class="link"
        :href="content?.Link"
        v-show="inView"
        target="_blank"
      >
        <span>{{ linkName }}</span>
        <Icon
          name="pin-outline"
          class="icon"
        />
      </a>
    </transition>
  </div>
</template>

<script>
import Close from '../../../components/Close.vue';
import Icon from '../../../components/Icon.vue';
import { animationMixin } from '../../../components/mixins/animation';

export default {
  components: { Close, Icon },
  name: 'OfficePopover',
  mixins: [animationMixin],
  emits: ['closePopover'],
  props: {
    content: {
      type: Object,
    },
    linkName: {
      type: String,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    closePopover() {
      this.$emit('closePopover');
    },
  },
};
</script>

<style lang="scss" scoped>
.office-popover {
  position: absolute;
  display: block;
  background: color(bg);
  box-sizing: border-box;
  z-index: 5;
  transform: translate3d(-50%, 0, 0);

  @include wRule(
    width,
    (
      xl: 340,
      lg: 340,
      md: 340,
      sm: 340,
    )
  );

  @include wRule(
    padding,
    (
      xl: 30 40 40,
      lg: 30 40 40,
      md: 30 40 40,
      sm: 80 45,
    )
  );

  @include wRule(
    margin-top,
    (
      xl: 21,
      lg: 21,
      md: 21,
      sm: 10,
    )
  );

  @include screen("sm", max) {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    z-index: 100;
    transform: translate3d(0, 0, 0);
    overflow: auto;
  }

  &.fade-enter-active {
    transition: all 0.3s ease;
  }

  &.fade-leave-active {
    transition: all 0.3s ease;
  }

  ::v-deep(.title) {
    font-weight: 700;
    line-height: 1.72;

    @include wRule(
      font-size,
      (
        xl: 25,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );

    @include wRule(
      max-width,
      (
        sm: 400,
      )
    );

    &.fade-up-enter-active {
      transition-delay: 0.3s;
    }

    &.fade-up-leave-active {
      transition-delay: 0.3s;
    }
  }

  .description {
    font-weight: 400;
    line-height: 1.2;

    @include wRule(
      margin-top,
      (
        xl: 21,
        lg: 21,
        md: 21,
        sm: 10,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 25,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );

    @include wRule(
      max-width,
      (
        sm: 400,
      )
    );

    &.fade-up-enter-active {
      transition-delay: 0.4s;
    }

    &.fade-up-leave-active {
      transition-delay: 0.4s;
    }
  }

  .link {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.72;
    font-weight: 700;
    transition: color $ease-main;

    @include wRule(
      padding-bottom,
      (
        xl: 14,
        lg: 14,
        md: 14,
        sm: 14,
      )
    );
    @include wRule(
      margin-top,
      (
        xl: 24,
        lg: 24,
        md: 24,
        sm: 28,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 18,
        lg: 18,
        md: 18,
        sm: 30,
      )
    );

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform-origin: 0 50%;

      @include wRule(
        height,
        (
          xl: 4,
          lg: 4,
          md: 4,
          sm: 4,
        )
      );

      @include screen("sm", max) {
        position: static;
        display: inline-block;
        margin-right: pxtovw(24, sm);
      }
    }

    @media (hover: hover) {
      &:hover {
        color: color(main);
      }
    }

    span {
      @include screen("sm", max) {
        flex: none;
      }
    }

    &.fade-up-enter-active,
    &.fade-up-leave-active {
      transition: all 0.3s ease;
      transition-delay: 0.5s;

      &::before {
        transition: all 0.3s ease;
        transition-delay: 0.5s;
      }
    }

    &.fade-up-enter-from,
    &.fade-up-leave-to {
      transform: translate3d(0, 20px, 0);
      opacity: 0;

      &::before {
        transform: scaleX(0);
      }
    }
  }

  .icon {
    flex: none;

    @include wRule(
      width,
      (
        xl: 14,
        lg: 14,
        md: 14,
        sm: 19,
      )
    );
    @include wRule(
      margin-left,
      (
        xl: 19,
        lg: 19,
        md: 19,
        sm: 20,
      )
    );
  }

  .close {
    position: absolute;
    top: pxtovw(22, sm);
    right: pxtovw(35, sm);
  }
}
</style>
