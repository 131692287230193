<template>
  <PageSection
    class="screen about-team-section"
    :theme="true"
    :bg="undefined"
  >
    <template #default>
      <div class="header">
        <div class="container">
          <h2
            class="title"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
          >{{content?.Title}}</h2>
        </div>
      </div>
      <transition name="fade">
        <div
          class="grid"
          v-if="breakpoint !== 'sm'"
        >
          <div class="column-left">
            <TeamCard
              :content="content?.TeamGroups[0]"
              class="big"
              v-inview-animate:repeat="{name: 'fade', duration: 500, delay: 800}"
            />
          </div>
          <div class="column-right">
            <TeamCard
              :content="content?.TeamGroups[1]"
              v-inview-animate:repeat="{name: 'fade', duration: 500, delay: 900}"
            />
            <TeamCard
              :content="content?.TeamGroups[2]"
              v-inview-animate:repeat="{name: 'fade', duration: 500, delay: 1000}"
            />
          </div>
        </div>
        <Swiper
          class="team-slider"
          v-else
          :speed="500"
          :slidesPerView="1"
          :pagination="{type: 'bullets'}"
          :runCallbacksOnInit="true"
          :normalizeSlideIndex="true"
        >
          <SwiperSlide
            v-for="department in content?.TeamGroups"
            :key="department?.Name"
          >
            <TeamCard :content="department" />
          </SwiperSlide>
        </Swiper>
      </transition>
    </template>
  </PageSection>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination } from 'swiper/core';
import 'swiper/swiper.scss';
import PageSection from '../../../components/PageSection.vue';
import TeamCard from './TeamCard.vue';

SwiperCore.use([Pagination]);

export default {
  components: {
    PageSection,
    Swiper,
    SwiperSlide,
    TeamCard,
  },
  name: 'AboutTeamSection',
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
};
</script>

<style lang="scss" scoped>
.about-team-section {
  position: relative;
  display: flex;
  will-change: transform, z-index;
  backface-visibility: hidden;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 3;

    @include wRule(
      padding-top,
      (
        xl: pxtovh(188, xl),
        lg: pxtovh(124, lg),
        md: pxtovh(134, md),
        sm: 94,
      )
    );
  }

  .grid {
    display: flex;
    width: 100%;

    @include screen("sm", max) {
      display: block;
    }

    &.fade-enter-active {
      transition: all 0.3s ease;
    }

    &.fade-leave-active {
      transition: all 0.3s ease;
    }
  }

  .column-left {
    display: flex;

    @include rule(
      width,
      (
        xl: 55.7%,
        md: 55.57%,
        sm: 100%,
      )
    );
  }

  .column-right {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    flex: 1;
  }

  ::v-deep(.swiper-pagination) {
    position: absolute;
    bottom: pxtovw(106, sm);
    top: auto;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  ::v-deep(.swiper-pagination-bullet) {
    display: block;
    width: pxtovw(10, sm);
    height: pxtovw(10, sm);
    border-radius: 50%;
    background-color: color(basic-light);
    transition: background $ease-main;

    &:not(:last-child) {
      margin-right: pxtovw(30, sm);
    }

    &.swiper-pagination-bullet-active {
      background-color: color(gray);
    }
  }
}
</style>
