import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home/Home.vue';
import About from '../views/About/About.vue';
import Base from '../views/Base/Base.vue';
import Fleet from '../views/Fleet/Fleet.vue';
import Services from '../views/Services/Services.vue';
import Contacts from '../views/Contacts/Contacts.vue';
import store from '@/store';
import PrivacyPolicy from '../views/Policy/PrivacyPolicy.vue';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Home,
  },
  {
    path: '/about/:section?',
    name: 'About',
    component: About,
    params: true,
  },
  {
    path: '/fleet',
    name: 'Fleet',
    component: Fleet,
    // redirect: {
    //   path: '/about/fleet',
    // },
  },
  {
    path: '/tools',
    redirect: () => ({
      path: '/',
      hash: 'tools',
    }),
  },
  {
    path: '/base',
    name: 'Base',
    component: Base,
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 500);
    });
  },
});

router.beforeEach((to) => {
  if (to?.name) {
    document.title = `${store.getters.getNavigationData?.[`${to.name}`]?.title || to.name} | СКАЙПРО Хеликоптерс`;
  }
});

export default router;
