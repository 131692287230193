<template>
  <ul class="features">
    <template
      v-for="(feature, featureId) in content"
      :key="featureId"
    >
      <li
        class="feature"
        v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: (500 + featureId * 100)}"
      >
        <div class="image">
          <img
            :src="getUrl(feature?.Icon?.url)"
            :alt="feature?.Name"
            :class="`icon icon_${feature?.Icon?.name.split('.')[0]}`"
          />
        </div>
        <div
          class="text"
          v-html="feature?.Name"
        ></div>
      </li>
    </template>
  </ul>
</template>

<script>
import getUrl from '../../../../../tools/getUrl';

export default {
  name: 'Features',
  props: {
    content: {
      type: Array,
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.features {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .feature {
    display: block;
    box-sizing: border-box;

    @include wRule(
      width,
      (
        xl: 290,
        lg: 224,
        md: 183,
        sm: 50%,
      )
    );

    @include wRule(
      padding,
      (
        xl: pxtovh(60, xl) 0,
        lg: pxtovh(38, lg) 0,
        md: pxtovh(32, md) 0,
        sm: 47 0 47 47,
      )
    );

    @include screen("xxl", min) {
      width: 21%;
    }

    @include wRule(
      margin-right,
      (
        xl: 75,
        lg: 39,
        md: 13,
        sm: 0,
      )
    );

    &:nth-child(4n) {
      @include screen('sm', min) {
        margin-right: 0;
      }
    }

    // &:nth-child(1) {
    //   .text {
    //     @include wRule(
    //       max-width,
    //       (
    //         xl: 300,
    //         lg: none,
    //       )
    //     );
    //   }
    // }

    // &:nth-child(2) {
    //   .text {
    //     @include wRule(
    //       max-width,
    //       (
    //         xl: 340,
    //         lg: none,
    //       )
    //     );
    //   }
    // }

    // &:nth-child(3) {
    //   .text {
    //     @include wRule(
    //       max-width,
    //       (
    //         xl: 280,
    //         lg: none,
    //       )
    //     );
    //   }
    // }
  }

  .image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @include wRule(
      height,
      (
        xl: 55,
        lg: 47,
        md: 37,
        sm: 55,
      )
    );

    .icon {
      flex-shrink: 0;
      max-height: 100%;
      max-width: 100%;

      &_helicopter {
        @include wRule(
          height,
          (
            xl: 30,
            lg: 20,
            md: 20,
            sm: 30,
          )
        );
      }

      &_wave {
        @include wRule(
          height,
          (
            xl: 52,
            lg: 41,
            md: 33,
            sm: 53,
          )
        );
      }

      &_audit {
        @include wRule(
          height,
          (
            xl: 44,
            lg: 36,
            md: 31,
            sm: 44,
          )
        );
      }
    }
  }

  .text {
    font-weight: 700;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 25,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: pxtovh(40, xl),
          lg: pxtovh(26, lg),
          md: pxtovh(20, md),
          sm: 30,
        )
      );
    }
  }
}

</style>
