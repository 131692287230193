<template>
  <PageSection
    :bg="bg"
    class="screen bases-section"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <h2
          class="title-big"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
        >{{content?.Title}}</h2>
        <p
          class="text"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 600}"
          v-html="content?.Text"
        ></p>
      </div>
    </template>
    <template #end>
      <BlockLink
        :href="'/base'"
        v-inview-animate:repeat="{name: 'block-link'}"
      >
        <div class="block-link__wrapper">
          <span>{{ content?.LinkText }}</span>
          <Icon name="arrow" />
        </div>
      </BlockLink>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import Icon from '../../../components/Icon.vue';
import BlockLink from '../../../components/BlockLink.vue';

export default {
  name: 'BasesSection',
  components: { PageSection, Icon, BlockLink },
  props: {
    bg: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.bases-section {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  will-change: transform, z-index;
  backface-visibility: hidden;

  @include wRule(
    padding-bottom,
    (
      xl: 90,
      lg: 90,
      md: 112,
      sm: 325,
    )
  );

  @include wRule(
    padding-top,
    (
      xl: 90,
      lg: 90,
      md: 90,
      sm: 248,
    )
  );

  ::v-deep(.container) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .title-big,
  .text {
    display: block;
    box-sizing: border-box;
    width: 100%;

    @include wRule(
      max-width,
      (
        xl: 570,
        lg: 450,
        md: 400,
        sm: none,
      )
    );
  }

  .text {
    line-height: 1.6;

    @include wRule(
      padding-top,
      (
        xl: 24,
        lg: 0,
      )
    );

    @include wRule(
      min-height,
      (
        xl: 275,
        lg: 176,
        md: 0,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 85,
          lg: 78,
          md: 60,
          sm: 48,
        )
      );
    }
  }
}
</style>
