<template>
  <section class="base-info">
    <BgMedia :media="getBackground(content)" />
    <div class="container">
      <div class="content">
        <div
          class="symbol"
          v-inview-animate:once="{name: 'fade-down', duration: 500, delay: 300}"
        >
          <Icon name="pin-outline" />
        </div>
        <h3
          class="title"
          v-inview-animate:once="{name: 'fade-down', duration: 500, delay: 400}"
        >{{ content?.name }}</h3>
        <p
          class="text"
          v-html="content?.description"
          v-inview-animate:once="{name: 'fade-down', duration: 500, delay: 500}"
        ></p>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '../../../components/Icon.vue';
import BgMedia from '../../../components/BgMedia.vue';

export default {
  components: { BgMedia, Icon },
  name: 'BaseInfo',
  props: {
    content: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {
    getBackground(screen) {
      return {
        background: screen?.background,
        backgroundMobile: screen?.background_mobile,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-info {
  position: relative;
  display: flex;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  color: color(basic-light);
  background-color: color(dark);
  box-sizing: border-box;

  // @include screen("sm", max) {
  //   min-height: pxtovw(617, sm);
  // }

  @include wRule(
    padding,
    (
      xl: 160 0 44,
      lg: 160 0 50,
      md: 200 0 43,
      sm: 116 0,
    )
  );

  ::v-deep(.bg-media) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        rgba(20, 24, 31, 0) 0%,
        rgba(20, 24, 31, 0.85) 100%
      );
      background-repeat: no-repeat;
      background-position: 0 0;
      z-index: 2;
    }
  }

  ::v-deep(.container) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .content {
    position: relative;
    display: block;
    z-index: 2;

    @include wRule(
      max-width,
      (
        xl: 500,
        lg: 420,
        md: 370,
        sm: 100%,
      )
    );
  }

  .symbol {
    @include wRule(
      width,
      (
        xl: 22,
        lg: 16,
        md: 16,
        sm: 22,
      )
    );
    @include wRule(
      height,
      (
        xl: 31,
        lg: 22,
        md: 22,
        sm: 31,
      )
    );
  }

  .title {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 42,
          lg: 27,
          md: 27,
          sm: 42,
        )
      );
    }
  }

  .text {
    @include screen("sm", max) {
      line-height: 1.6;
    }

    @include wRule(
      min-height,
      (
        xl: 200,
        lg: 145,
        md: 145,
        sm: 0,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 90,
          lg: 70,
          md: 40,
          sm: 48,
        )
      );
    }
  }

  &.fullscreen {
    min-height: calc(var(--vh, 1vh) * 100);

    @include screen("sm", max) {
      transition: min-height 100s ease;
    }
  }

  &_half {
    width: 50%;

    @include screen("sm", max) {
      width: 100%;
    }
  }
}
</style>
