<template>
  <PageSection
    class="screen service-section"
    :bg="bg"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <h2
          class="title-big"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
        >{{content?.Title}}</h2>
        <Features :content="content?.Services" />
      </div>
    </template>
    <template #end>
      <BlockLink
        :href="'/services'"
        v-inview-animate:repeat="{name: 'block-link'}"
      >
        <div class="block-link__wrapper">
          <span>{{ content?.LinkText }}</span>
          <Icon name="arrow" />
        </div>
      </BlockLink>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../../components/PageSection.vue';
import BlockLink from '../../../../components/BlockLink.vue';
import Icon from '../../../../components/Icon.vue';
import Features from './components/Features.vue';

export default {
  components: {
    PageSection,
    Icon,
    BlockLink,
    Features,
  },
  name: 'ServiceSection',
  props: {
    bg: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.service-section {
  display: flex;
  width: 100%;
  will-change: transform, z-index;
  backface-visibility: hidden;

  @include wRule(
    padding,
    (
      xl: pxtovh(170, xl) 0 pxtovh(145, xl),
      lg: pxtovh(122, lg) 0 pxtovh(86, lg),
      md: pxtovh(174, md) 0 pxtovh(85, md),
      sm: 118 0 302,
    )
  );

  ::v-deep(.container) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .features {
    margin: auto 0;
    box-sizing: border-box;
    width: 100%;

    // @include wRule(
    //   padding-left,
    //   (
    //     xl: 122,
    //     lg: 90,
    //     md: 0,
    //     // sm: 48,
    //   )
    // );

    @include screen("sm", max) {
      margin: pxtovw(35, sm) 0 0;
    }
  }
}
</style>
