<template>
  <div
    class="slider-arrows"
    :class="{'is-hide': isLock, 'is-counter': counter}"
    v-observe-visibility="{ callback: animation, once:true}"
  >
    <button
      type="button"
      class="arrow prev"
      :disabled="swiper?.isBeginning"
      @click="prev"
    >
      <Icon
        name="next"
        class="icon"
      />
    </button>
    <div
      class="counter"
      v-if="counter"
      :key="counterText"
    >{{counterText}}</div>
    <button
      type="button"
      class="arrow"
      :disabled="swiper?.isEnd"
      @click="swiper?.slideNext()"
    >
      <Icon
        name="next"
        class="icon"
      />
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue';
import { animationMixin } from './mixins/animation';

export default {
  name: 'SliderArrows',
  components: { Icon },
  mixins: [animationMixin],
  props: {
    swiper: {
      // eslint-disable-next-line no-undef
      type: Object,
      required: true,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    counterText: {
      type: String,
    },
  },
  computed: {
    isLock() {
      return this.swiper?.isLocked;
    },
  },
  methods: {
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-arrows {
  display: flex;
  align-items: center;
  color: color(basic);
  position: relative;

  &.is-hide {
    display: none;
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
  }

  .arrow {
    display: block;
    transform-origin: 50% 50%;
    color: inherit;
    transition: color $ease-main, opacity $ease-main;
    font-size: 0;
    line-height: 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -100%;
      top: -100%;
      right: -100%;
      bottom: -100%;
    }

    &.prev {
      transform: rotate(180deg);
    }

    &:hover {
      @include screen("sm", min) {
        color: color(main);
      }
    }

    &[disabled] {
      opacity: 0.2;
      cursor: default;
      pointer-events: none;
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 60,
          lg: 40,
          md: 65,
          sm: 90,
        )
      );
    }
  }

  .icon {
    display: block;

    @include wRule(
      height,
      (
        xl: 40,
        lg: 30,
        md: 30,
        sm: 40,
      )
    );
  }

  .counter {
    font-weight: normal;
    line-height: 1.4;
    white-space: nowrap;

    @include wRule(
      font-size,
      (
        xl: 18,
        lg: 18,
        md: 16,
        sm: 28,
      )
    );
    @include wRule(
      margin,
      (
        xl: 0 15,
        lg: 0 16,
        md: 0 10,
        sm: 0 20,
      )
    );
  }

  &.is-counter {
    .prev {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  /*
  Themes
   */
  &.theme-dark {
    color: color(basic);

    .arrow[disabled] {
      opacity: 0.2;
    }
  }

  &.theme-white {
    color: color(bg);

    .arrow[disabled] {
      opacity: 0.5;
    }
  }
}
</style>
