<template>
  <div
    class="site-navigation"
    :class="{'is-active': isActive}"
  >
    <div class="header">
      <!-- <transition name="scalein">
        <LangButton v-show="isShown" />
      </transition> -->
      <!-- <transition name="scalein">
        <Socials v-show="isShown" />
      </transition> -->
      <transition name="scalein-rotate">
        <Close
          @click="closeNavigation"
          v-show="isShown"
        />
      </transition>
    </div>
    <div class="content">
      <nav>
        <transition-group
          name="slide-fade"
          tag="div"
        >
          <div
            class="item"
            v-for="(link, linkId) in content?.navigation"
            :key="`nav-${linkId}`"
            v-show="isShown"
          >
            <NavigationLink
              v-if="link"
              :tag="link.popup ? 'button' : 'router-link'"
              :type="link.popup ? 'button' : undefined"
              :to="link.popup ? undefined : link?.href"
              @click="link.popup ? popupShow(link?.popup) : $emit('hideNavigation')"
              :class="{'is-active': (link?.href !== '/' && $route.path.includes(link.href))}"
            >{{link?.title}}</NavigationLink>
          </div>
        </transition-group>
      </nav>
      <div class="contacts">
        <transition-group name="slide-fade">
          <Link
            v-if="content.contacts.Phone"
            :tag="'a'"
            :href="`tel:${content?.contacts?.Phone.replace(/ /gi, '')}`"
            v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: 400}"
          >
          <template #start>
            <Icon :name="'phone'" />
          </template>
          <template #default>{{content?.contacts?.Phone}}</template>
          </Link>

          <Link
            v-if="content.contacts.Email"
            :tag="'a'"
            :href="`mailto:${content?.contacts?.Email}`"
            v-inview-animate:repeat="{name: 'fade-right', duration: 500, delay: 600}"
          >
          <template #start>
            <Icon :name="'mail'" />
          </template>
          <template #default>{{content?.contacts?.Email}}</template>
          </Link>
        </transition-group>
      </div>

    </div>
  </div>
</template>

<script>
// import LangButton from './LangButton.vue';
// import Socials from '../../Socials.vue';
import Close from '../../Close.vue';
import NavigationLink from './NavigationLink.vue';
import Link from '../../Link.vue';
import Icon from '../../Icon.vue';

export default {
  name: 'SiteNavigation',
  components: {
    Close,
    // LangButton,
    // Socials,
    NavigationLink,
    Link,
    Icon,
  },
  emits: ['hideNavigation', 'openPopup'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupActive: false,
      currentPopup: 'div',
    };
  },
  computed: {
    navigationData() {
      return this.$store.getters.getNavigationData;
    },
    content() {
      return {
        navigation: [
          this.navigationData?.Main,
          this.navigationData?.About,
          this.navigationData?.Services,
          this.navigationData?.Fleet,
          this.navigationData?.Certificates,
          this.navigationData?.Base,
          this.navigationData?.Tools,
          // this.navigationData?.Alliance,
          this.navigationData?.Contacts,
        ],
        contacts: this.$store.getters.getNavigationContacts,
      };
    },
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    closeNavigation() {
      this.$emit('hideNavigation');
    },
    popupShow(component) {
      this.$emit('open-popup', component);
      this.$emit('hideNavigation');
    },
  },
};
</script>

<style lang="scss" scoped>
.site-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background-color: color(dark);
  color: color(basic-light);
  pointer-events: none;
  overflow: hidden;
  z-index: 25;

  @include screen("sm", max) {
    overflow: auto;
    max-height: 100%;
  }

  @include wRule(
    width,
    (
      xl: 925,
      lg: 683,
      md: 640,
      sm: 100%,
    )
  );

  &.is-active {
    pointer-events: auto;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    flex: none;
    width: 100%;

    @include wRule(
      padding,
      (
        xl: 42 98 0 225,
        lg: 30 65 0 125,
        md: 42 55 0 125,
        sm: 23 37 23 92,
      )
    );
  }

  ::v-deep(.close) {
    transform-origin: 50% 50%;

    // @include wRule(
    //   margin-left,
    //   (
    //     xl: 56,
    //     lg: 42,
    //     sm: 58,
    //   )
    // );

    @include wRule(
      margin-left,
      (
        xl: 54,
        lg: 44,
        md: 40,
        sm: 55,
      )
    );

    @include wRule(
      width,
      (
        xl: 54,
        lg: 46,
        md: 46,
        sm: 50,
      )
    );

    @include wRule(
      height,
      (
        xl: 54,
        lg: 46,
        md: 46,
        sm: 50,
      )
    );
  }

  .lang-button {
    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 42,
          md: 40,
          sm: 0,
        )
      );
    }
  }

  .socials {
    &:not(:first-child) {
      @include wRule(
        margin-left,
        (
          xl: 52,
          lg: 42,
          md: 40,
          sm: 58,
        )
      );
    }
  }

  // ::v-deep(.social) {
  //   @include wRule(
  //     width,
  //     (
  //       sm: 36,
  //     )
  //   );
  //   @include wRule(
  //     height,
  //     (
  //       sm: 36,
  //     )
  //   );

  //   &:not(:first-child) {
  //     @include wRule(
  //       margin-left,
  //       (
  //         xl: 32,
  //         lg: 26,
  //         sm: 46,
  //       )
  //     );
  //   }
  // }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    @include wRule(
      padding,
      (
        xl: pxtovh(90, xl) 108 pxtovh(90, xl) 225,
        lg: pxtovh(40, lg) 75 pxtovh(40, lg) 125,
        md: pxtovh(40, md) 75 pxtovh(40, md) 125,
        sm: 82 92 140 92,
      )
    );
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    @include screen("sm", max) {
      margin: 0 pxtovw(-40, sm) 0 0;
    }
  }

  .item {
    display: block;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 35,
        lg: 30,
        md: 28,
        sm: 45,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 14,
          lg: 7,
          md: 10,
          sm: 9,
        )
      );
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @include screen("sm", max) {
      margin-top: pxtovw(72, sm);
    }
  }

  .link {
    @include wRule(
      margin-top,
      (
        xl: 14,
        lg: 14,
        md: 10,
        sm: 14,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    ::v-deep(.icon) {
      @include wRule(
        width,
        (
          xl: 34,
          lg: 24,
          md: 25,
          sm: 48,
        )
      );

      @include wRule(
        height,
        (
          xl: 34,
          lg: 24,
          md: 25,
          sm: 48,
        )
      );

      &:not(:last-child) {
        @include wRule(
          margin-right,
          (
            xl: 21,
            lg: 17,
            md: 13,
            sm: 43,
          )
        );
      }
    }
  }
}
</style>
