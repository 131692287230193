<template>
  <div class="page-container">
    <Swiper
      class="about-slider"
      :speed="500"
      :init="false"
      :autoHeight="true"
      :slidesPerView="'auto'"
      :followFinger="false"
      :direction="'veritcal'"
      :observer="true"
      :observeSlideChildren="true"
      :keyboard="{enabled: true}"
      :resizeObserver="true"
      :simulateTouch="false"
      :resistance="false"
      :preventInteractionOnTransition="true"
      :initialSlide=initialSlide
      @swiper="swiperInstance = $event"
      @slideNextTransitionStart="slideMoveDown"
      @slidePrevTransitionStart="slideMoveUp"
      @reachEnd="sliderReachEnd"
      @fromEdge="sliderGoAwayFromEnd"
      @activeIndexChange="slideActiveChange"
      @afterInit="listenInit"
    >
      <SwiperSlide v-slot="{ isActive, isPrev }">
        <AboutSection
          :class="{'is-leave-animation': isPrev && isDown,'is-enter-animation': (isActive && !isDown)}"
          :content="pageContent?.SectionMain?.AboutItems"
        />
      </SwiperSlide>

      <!-- Fleet -->
      <!-- <SwiperSlide v-slot="{isActive, isPrev, isNext }">
        <FleetSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          :content="pageContent?.SectionFleet"
          id="fleet"
          ref="fleet"
        />
      </SwiperSlide> -->

      <SwiperSlide v-slot="{isActive, isPrev, isNext }">
        <AboutTeamSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          :content="pageContent?.SectionTeam"
        />
      </SwiperSlide>

      <!-- Certificates -->

      <!-- <SwiperSlide v-slot="{isActive, isPrev, isNext }">
        <CertificatesSection
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          :content="pageContent?.SectionCertificates"
        />
      </SwiperSlide> -->

      <SwiperSlide v-slot="{isActive, isPrev, isNext }">
        <PageFooter :class="{'is-leave-animation': isPrev, 'is-active-animation': isActive, 'is-enter-animation': isNext}" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Keyboard } from 'swiper/core';
import 'swiper/swiper.scss';
// import CertificatesSection from './components/CertificatesSection.vue';
import AboutSection from './components/AboutSection.vue';
import AboutTeamSection from './components/AboutTeamSection.vue';
// import FleetSection from './components/FleetSection.vue';
import PageFooter from '../../components/PageFooter.vue';
import api from '../../tools/api';
import smoothScrollToAnchor from '../../tools/smoothScrollToAnchor';

SwiperCore.use([Keyboard]);

export default {
  name: 'About',
  components: {
    Swiper,
    SwiperSlide,
    // CertificatesSection,
    AboutSection,
    AboutTeamSection,
    // FleetSection,
    PageFooter,
  },
  data() {
    return {
      isDown: true,
      swiperInstance: undefined,
      transitionNextStart: false,
      transitionPrevStart: false,
      isSliderScroll: true,
      pageLoading: true,
      pageContent: {},
    };
  },
  emits: [
    'pageSliderScrollDown',
    'pageSliderScrollUp',
    'pageSliderReachFooter',
    'pageSliderGoAwayFooter',
  ],
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    slideMoveDown() {
      this.$emit('pageSliderScrollDown', this.swiperInstance?.activeIndex);
      this.isDown = true;
      this.transitionNextStart = true;
    },
    slideMoveUp() {
      this.transitionPrevStart = true;
      this.isDown = false;
      this.$emit('pageSliderScrollUp', this.swiperInstance?.activeIndex);
    },
    sliderReachEnd() {
      this.$emit('pageSliderReachFooter');
    },
    sliderGoAwayFromEnd() {
      this.$emit('pageSliderGoAwayFooter');
    },
    swiperManage() {
      if (this.breakpoint === 'sm') {
        this.swiperInstance.destroy(false, true);
      } else {
        this.swiperInstance.init();
        this.swiperInstance.update();
      }
    },
    stopMouseWheel(e) {
      e.preventDefault();
    },
    manualsScrolling(evt) {
      const swp = this.swiperInstance;

      if (this.isSliderScroll) {
        evt.preventDefault();
        evt.stopPropagation();
        const diff = window.lethargy.check(evt);
        if (diff !== false && !this.swiperInstance.animating) {
          if (diff > 0) {
            swp.slidePrev(500);
          } else {
            swp.slideNext(500);
          }
        }
      }
    },
    listenInit() {
      window.addEventListener('wheel', this.manualsScrolling, {
        passive: false,
      });
    },
    slideActiveChange(sw) {
      this.slideActive = sw.activeIndex;
    },
    isFleetStart(params) {
      const hashId = this.$route.params?.section;
      const isFleet = hashId ? hashId === 'fleet' : false;
      const isMobile = this.breakpoint === 'sm';
      if (isFleet && !params) {
        if (isMobile) {
          this.$nextTick(() => {
            const target = document.getElementById(hashId);
            smoothScrollToAnchor(target);
          });
        } else {
          this.initialSlide = 1;
        }
      } else if (isFleet && params) {
        if (isMobile) {
          this.$nextTick(() => {
            const target = document.getElementById(hashId);
            smoothScrollToAnchor(target);
          });
        } else {
          this.swiperInstance.slideTo(1, 500);
        }
      } else {
        this.initialSlide = 0;
      }
    },
    async getPageContent() {
      const results = await api('page-about-content', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    async getFleetData() {
      const fleetGroups = await api('fleet-groups', false);
      this.$store.commit('setFleetGroups', fleetGroups.data);
      const fleetModels = await api('fleet-models', false);
      this.$store.commit('setFleetModels', fleetModels.data);
      const fleetParams = await api('fleet-params-names', true);
      this.$store.commit('setFleetParams', fleetParams.data);
    },
  },
  watch: {
    breakpoint() {
      this.swiperManage();
    },
    lang() {
      this.getPageContent();
      // this.getFleetData();
    },
    $route() {
      this.isFleetStart();
    },
    '$route.params': function () {
      this.isFleetStart(true);
    },
  },
  created() {
    const isMobile = this.breakpoint === 'sm';
    this.getPageContent();
    this.getFleetData();
    if (!isMobile) {
      this.isFleetStart();
    }
  },
  mounted() {
    this.swiperManage();
    const isMobile = this.breakpoint === 'sm';
    if (isMobile) {
      this.isFleetStart();
    }
  },
  beforeUnmount() {
    window.removeEventListener('wheel', this.manualsScrolling, {
      passive: false,
    });
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  position: relative;
  width: 100%;
}

.about-slider {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  @include screen("sm", max) {
    height: auto;
    overflow: auto;
  }

  ::v-deep(> .swiper-wrapper) {
    flex-direction: column;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);

    @include screen("sm", max) {
      height: auto !important;
    }
  }

  ::v-deep(.swiper-slide) {
    @include screen("sm", max) {
      height: auto;
    }
  }
}

.page-section {
  position: relative;
  transform-origin: 100% 100%;
}
</style>
