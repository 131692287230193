// eslint-disable-next-line import/prefer-default-export
export const animationMixin = {
  data() {
    return {
      inView: false,
    };
  },
  methods: {
    animation(isVisible) {
      if (isVisible) {
        this.inView = true;
      } else {
        this.inView = false;
      }
    },
  },
};
