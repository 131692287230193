<template>
  <PageSection class="team-section">
    <template #default>
      <div class="container">
        <div class="header">
          <div class="column-left">
            <h3
              class="title"
              v-inview-animate="{name: 'fade-down', duration: 500, delay: 300}"
            >{{content?.title}}</h3>
          </div>

          <div class="column-right">
            <SliderArrows
              :swiper="swiperInstance"
              v-inview-animate="{name: 'fade-down', duration: 500, delay: 500}"
            />
          </div>

        </div>
        <Swiper
          :watchOverflow="true"
          @swiper="swiperInstance = $event"
          :slides-per-view="slidesPerView"
          :space-between="spaceBetween"
          v-inview-animate="{name: 'fade-down', duration: 500, delay: 600}"
        >
          <SwiperSlide
            v-for="member in content?.team"
            :key="member?.Name"
          >
            <PersonShortCard
              :data="member"
              @show-full-info="openPersonPopup"
            />
          </SwiperSlide>
        </Swiper>
        <teleport to="#popups">
          <transition name="fade">
            <Popup
              v-if="personPopupActive"
              :isShown="personPopupActive"
              @modal-closed="personPopupActive = false"
            >
              <template #default>
                <PersonCard :data="currentPerson" />
              </template>
            </Popup>
          </transition>
        </teleport>
      </div>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../../components/PageSection.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import PersonShortCard from './components/PersonShortCard.vue';
import PersonCard from './components/PersonCard.vue';
import Popup from '../../../../components/Popup.vue';
import SliderArrows from '../../../../components/SliderArrows.vue';

export default {
  components: {
    PageSection,
    Swiper,
    SwiperSlide,
    PersonShortCard,
    PersonCard,
    Popup,
    SliderArrows,
  },
  name: 'TeamSection',
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      swiperInstance: {},
      personPopupActive: false,
      currentPerson: null,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    slidesPerView() {
      switch (this.breakpoint) {
        case 'lg':
          return 4;

        case 'md':
          return 3;

        case 'sm':
          return 1;

        default:
          return 4;
      }
    },
    spaceBetween() {
      switch (this.breakpoint) {
        case 'lg':
          return Math.floor((14 / 1366) * this.windowWidth);

        case 'md':
          return Math.floor((20 / 1024) * this.windowWidth);

        case 'sm':
          return Math.floor((32 / 640) * this.windowWidth);

        default:
          return Math.floor((20 / 1920) * this.windowWidth);
      }
    },
  },
  methods: {
    openPersonPopup(person) {
      this.personPopupActive = true;
      this.currentPerson = person;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-section {
  overflow: hidden;

  @include wRule(
    padding,
    (
      xl: 165 0 65,
      lg: 145 0 25,
      md: 90 0 25,
      sm: 162 0 25,
    )
  );

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 120,
          lg: 80,
          md: 40,
          sm: 125,
        )
      );
    }
  }

  .column-left {
    @include wRule(
      width,
      (
        xl: 860,
        lg: 790,
        md: 480,
        sm: 100%,
      )
    );
  }

  .column-right {
    @include rule(
      padding-bottom,
      (
        xl: 0.8em,
        lg: 0.6em,
        md: 0.8em,
        sm: 0.6em,
      )
    );
  }
}

.swiper-container {
  overflow: visible;
  box-sizing: border-box;
  width: 100%;

  @include wRule(
    padding-right,
    (
      sm: 130,
    )
  );
}

.swiper-slide {
  width: auto;

  &:not(:last-child) {
    @include wRule(
      margin-right,
      (
        xl: 20,
        lg: 20,
        sm: 32,
      )
    );
  }
}
</style>
