<template>
  <section class="offices">
    <div class="container">
      <div class="grid">
        <OfficeCard
          v-for="office in content?.offices"
          :key="office?.City"
          :content="office"
          :linkName="content?.linkName"
        />
      </div>
    </div>
  </section>
</template>

<script>
import OfficeCard from './OfficeCard.vue';

export default {
  components: { OfficeCard },
  name: 'Offices',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.offices {
  display: block;
  width: 100%;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @include screen("sm") {
      display: block;
    }

    @include wRule(
      column-gap,
      (
        xl: 54,
        lg: 54,
        md: 40,
        sm: 0,
      )
    );

    @include wRule(
      row-gap,
      (
        xl: 80,
        lg: 94,
        md: 74,
        sm: 120,
      )
    );
  }

  .office-card {
    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          sm: 114,
        )
      );
    }
  }
}
</style>
