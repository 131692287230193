<template>
  <section
    class="content-section"
    :class="{'is-bg': bgImage, 'is-dark': isDark}"
  >
    <slot name="background"></slot>
    <div class="content">
      <div class="main">
        <slot></slot>
      </div>
      <div
        class="side"
        v-if="!bgImage"
      >
        <slot name="side"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentSection',
  data() {
    return {
      observer: null,
    };
  },
  emits: ['inview'],
  props: {
    bgImage: {
      type: [Boolean, Object],
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    isObserve: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sectionInView(enteries) {
      enteries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          this.$emit('inview');
        }
      });
    },
    setObserver() {
      const observer = new IntersectionObserver(this.sectionInView, {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      });
      observer.observe(this.$el);
      this.observer = observer;
    },
    removeObserver() {
      this.observer.unobserve(this.$el);
    },
  },
  mounted() {
    if (this.isObserve) {
      this.setObserver();
    }
  },
  unmounted() {
    if (this.observer) {
      this.removeObserver();
    }
  },
};
</script>

<style lang="scss" scoped>
.content-section {
  $b: &;
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @include screen("lg", min) {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  @include screen("sm", max) {
    transition: min-height 100s ease;
  }

  ::v-deep(.bg-media) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    overflow: hidden;
    z-index: 1;
  }

  .content {
    position: relative;
    display: flex;
    z-index: 2;
    width: 100%;

    @include screen("md", max) {
      flex-wrap: wrap;
    }

    @include screen("xxl", min) {
      justify-content: space-between;
    }
  }

  .main {
    display: block;
    box-sizing: border-box;

    @include wRule(
      width,
      (
        xl: 1092,
        lg: 806,
        md: 1024,
      )
    );

    @include wRule(
      padding,
      (
        xl: 276 90 178 483,
        lg: 212 45 186 320,
        md: 167 135 74 304,
        sm: 111 45 105,
      )
    );

    @include screen("xxl", min) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-top: 276px;
      padding-bottom: 276px;
    }

    ::v-deep(.markdown-content) {
      @include wRule(
        max-width,
        (
          xl: 480,
          lg: 405,
          md: 550,
          sm: none,
        )
      );

      p {
        display: block;
        letter-spacing: -0.02em;
        font-weight: 400;
        line-height: 1.6;
        width: 100%;

        strong {
          font-weight: 700;
        }

        a {
          text-decoration: underline;
        }

        @include wRule(
          font-size,
          (
            xl: 20,
            lg: 18,
            md: 16,
            sm: 30,
          )
        );

        &:not(:last-child) {
          @include wRule(
            margin-bottom,
            (
              xl: 32,
              lg: 24,
              md: 18,
              sm: 30,
            )
          );
        }
      }

      ul {
        display: block;
        letter-spacing: -0.02em;
        font-weight: 400;
        line-height: 1.6;
        width: 100%;

        @include wRule(
          font-size,
          (
            xl: 20,
            lg: 18,
            md: 16,
            sm: 30,
          )
        );

        li {
          position: relative;
          display: block;
          box-sizing: border-box;

          @include wRule(
            padding-left,
            (
              xl: 72,
              lg: 54,
              md: 38,
              sm: 75,
            )
          );

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
            background: currentColor;
            margin-top: 0.7em;

            @include wRule(
              width,
              (
                xl: 20,
                lg: 15,
                md: 11,
                sm: 20,
              )
            );

            @include wRule(
              height,
              (
                xl: 3,
                lg: 2,
                md: 2,
                sm: 3,
              )
            );
          }

          &:not(:last-child) {
            @include wRule(
              margin-bottom,
              (
                xl: 16,
                lg: 15,
                md: 12,
                sm: 24,
              )
            );
          }
        }
      }

      &_big {
        p {
          line-height: 1.34;

          @include wRule(
            font-size,
            (
              xl: 26,
              lg: 21,
              md: 18,
              sm: 32,
            )
          );

          &:not(:last-child) {
            @include wRule(
              margin-bottom,
              (
                xl: 32,
              )
            );
          }
        }
      }
    }

    ::v-deep(.title-small) {
      display: block;
      width: 100%;

      &:not(:last-child) {
        @include wRule(
          margin-bottom,
          (
            xl: 70,
            lg: 64,
            md: 44,
            sm: 64,
          )
        );
      }
    }
  }

  .side {
    position: relative;
    display: block;
    flex: 1;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 284 0 178,
        lg: 218 0 186,
        md: 0 0 92 304,
        sm: 0 0 120,
      )
    );

    @include screen("md", max) {
      flex: none;
      width: 100%;
    }

    @include screen("xxl", min) {
      padding-left: pxtovw(147, xl);
    }
  }

  ::v-deep(.image) {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;

    @include ratio(828, 535);

    @include screen("lg", max) {
      @include ratio(560, 380);
    }

    @include screen("md", max) {
      @include ratio(720, 335);
    }

    @include screen("sm", max) {
      @include ratio(828, 535);
    }

    .bg-media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.is-bg {
    background-color: color(dark);
    color: color(basic-light);

    .main {
      background: rgba(0, 0, 0, 0.48);
      backdrop-filter: blur(11px);

      @include wRule(
        padding-bottom,
        (
          xl: 178,
          lg: 186,
          md: 168,
          sm: 105,
        )
      );

      @include screen("md", max) {
        backdrop-filter: none;
      }

      @include screen("xxl", min) {
        padding-bottom: 276px;
      }
    }
  }

  &.is-dark {
    background-color: color(dark);
    color: color(basic-light);

    ::v-deep(ul) {
      li {
        &::before {
          background-color: color(main);
        }
      }
    }
  }
}
</style>
