<template>
  <component
    :is="tag"
    class="v-button"
  >
    <div class="v-button__wrapper">
      <slot name="start"></slot>
      <span class="v-button__text">
        <slot></slot>
      </span>
      <slot name="end"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  $b: &;
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 400;
  border-style: solid;
  border-color: currentColor;
  line-height: 1;
  box-sizing: border-box;
  color: currentColor;
  cursor: pointer;
  letter-spacing: -0.02em;

  @include rule(
    border-width,
    (
      xl: 1px,
      lg: 1px,
      md: 1px,
      sm: 2px,
    )
  );

  @include wRule(
    padding,
    (
      xl: 0 10,
      lg: 0 10,
      md: 0 10,
      sm: 0 10,
    )
  );

  @include wRule(
    height,
    (
      xl: 50,
      lg: 42,
      md: 38,
      sm: 66,
    )
  );

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 16,
      sm: 30,
    )
  );

  &::before {
    @include pseudo;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity $ease-main;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        opacity: 0.05;
      }
    }
  }

  &:focus {
    &::before {
      @include screen("sm", min) {
        opacity: 0.1;
      }
    }
  }

  &__wrapper {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  ::v-deep(.icon) {
    &_pdf {
      flex-shrink: 0;

      @include wRule(
        width,
        (
          xl: 15,
          lg: 12,
          md: 10,
          sm: 20,
        )
      );
    }
  }

  &[disabled] {
    pointer-events: none;

    .v-button__wrapper {
      opacity: 0.5;
    }
  }
}
</style>
