<template>
  <transition name="fade-up">
    <div
      class="page-anchors"
      v-show="isShown"
    >
      <div
        class="anchor"
        v-for="(anchor, anchorIndex) in content"
        :key="`anchor-${anchorIndex}`"
        :class="{'is-active': anchorIndex === currentActive}"
      >{{anchor.name}}</div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PageAnchors',
  data() {
    return {
      visibleSection: 0,
    };
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    slideActive: {
      type: Number,
    },
    screenNames: {
      type: Object,
    },
  },
  computed: {
    content() {
      return [
        {
          name: this.screenNames?.ScreenOne?.ScreenName,
          theme: false,
        },
        {
          name: this.screenNames?.ScreenServices?.ScreenName,
          theme: false,
        },
        {
          name: this.screenNames?.ScreenBase?.ScreenName,
          theme: false,
        },
        {
          name: this.screenNames?.ScreenInstruments?.ScreenName,
          theme: false,
        },
        // {
        //   name: this.screenNames?.ScreenAlliance?.ScreenName,
        //   theme: false,
        // },
      ];
    },
    currentActive() {
      const activeIndex = this.slideActive;
      return activeIndex;
    },
  },
  methods: {
    intersectScreens() {
      const sections = document.querySelectorAll('[data-screen-section]');
      const inst = this;

      function getActiveSection(entries) {
        entries.forEach((entry) => {
          const progress = entry.intersectionRatio;
          const element = entry.target;
          if (progress >= 0.5) {
            const number = Number(element.getAttribute('data-screen-section'));
            inst.visibleSection = number;
          }
        });
      }

      const observer = new IntersectionObserver(getActiveSection, {
        threshold: [0, 0.5, 1],
      });

      sections.forEach((section) => {
        observer.observe(section);
      });
    },
  },
  mounted() {
    this.intersectScreens();
  },
};
</script>

<style lang="scss" scoped>
.page-anchors {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 12;
  transform-origin: 0 0;
  transform: rotate(-90deg) translate3d(0, 0, 0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  color: color(basic-light);

  @include wRule(
    height,
    (
      xl: 20,
      lg: 20,
      md: 20,
      sm: 0,
    )
  );
  @include wRule(
    left,
    (
      xl: 102,
      lg: 70,
      md: 50,
      sm: 0,
    )
  );

  @include wRule(
    bottom,
    (
      xl: 160,
      lg: 102,
      md: 68,
      sm: 0,
    )
  );

  @include screen("sm", max) {
    display: none;
  }

  &.is-dark {
    color: color(basic);
  }

  &.is-hide {
    opacity: 0;
  }

  &.fade-up-enter-active {
    transition: all 0.5s ease 0.5s;
  }

  &.fade-up-leave-active {
    transition: all 0.3s ease;
  }

  &.fade-up-enter-from,
  &.fade-up-leave-to {
    transform: rotate(-90deg) translate3d(-20px, 0, 0);
    opacity: 0;
  }
}

.anchor {
  display: inline-block;
  border-radius: 0;
  color: currentColor;
  background-color: currentColor;
  width: auto;
  font-size: 0;
  transition: background-color 0.25s ease, font-size 0.5s ease;
  overflow: hidden;
  letter-spacing: normal;
  cursor: default;

  @include wRule(
    min-height,
    (
      xl: 2,
      lg: 2,
      md: 1,
      sm: 1,
    )
  );

  @include wRule(
    min-width,
    (
      xl: 14,
      lg: 18,
      md: 14,
      sm: 14,
    )
  );

  &:not(:last-child) {
    @include wRule(
      margin-left,
      (
        xl: 8,
        lg: 10,
        md: 8,
        sm: 8,
      )
    );
  }

  &.is-hide {
    display: none;
  }

  &.is-active {
    background-color: transparent;
    width: auto;
    cursor: default;

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 12,
        sm: 12,
      )
    );
  }
}
</style>
