<template>
  <div class="page-container">
    <Breadcrumbs />
    <section
      class="services"
      v-if="!pageLoading"
    >
      <ContentSection
        v-for="(service, linkId) in services"
        :key="`service-${linkId}`"
        :id="`service-${linkId}`"
        :bgImage="service?.Theme === 'backgroundImage'"
        :isDark="service?.Theme === 'dark'"
        :isObserve="true"
        @inview="currentSection = `service-${linkId}`"
      >
        <h3
          class="title-small"
          v-inview-animate:once="{name: 'fade-down', duration: 500, delay: 300}"
          v-html="service?.Name"
        ></h3>
        <div
          class="markdown-content"
          v-inview-animate:once="{name: 'fade-down', duration: 500, delay: 400}"
          v-html="markdownToHTML(service?.Description || '')"
        >
        </div>
        <template #background>
          <BgMedia
            v-if="service?.Theme === 'backgroundImage'"
            :media="getBackground(service)"
          />
        </template>
        <template #side>
          <div
            class="image"
            v-if="service?.Theme === 'light' || service?.Theme === 'dark'"
          >
            <BgMedia :media="getBackground(service)" />
          </div>
        </template>
      </ContentSection>
      <SideNavigation
        :navigationList="services"
        :currentActive="currentSection"
      />
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import ContentSection from './components/ContentSection.vue';
import BgMedia from '../../components/BgMedia.vue';
import SideNavigation from './components/SideNavigation.vue';
import { animationMixin } from '@/components/mixins/animation';

// API
import api from '@/tools/api';

const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'Services',
  mixins: [animationMixin],
  components: {
    Breadcrumbs,
    ContentSection,
    BgMedia,
    SideNavigation,
  },
  data() {
    return {
      currentSection: undefined,
      pageLoading: true,
      pageContent: {},
    };
  },
  computed: {
    services() {
      const compare = (a, b) => parseInt(a?.Order, 10) - parseInt(b?.Order, 10);
      const list = this.pageContent?.Sections;
      return list.sort(compare);
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('page-services-content', true);
      this.pageLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    markdownToHTML(content) {
      return md.render(content);
    },
    getBackground(screen) {
      return {
        background: screen?.PictureOrVideo,
        backgroundMobile: screen?.PictureOrVideoMobile,
      };
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  created() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.services {
  position: relative;

  ::v-deep(.content-section:first-child) {
    .main {
      @include screen("sm", max) {
        padding-top: pxtovw(330, sm);
      }
    }
  }
}
</style>
