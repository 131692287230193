<template>
  <svg
    :class="className"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="iconViewBox"
    v-if="iconPath"
  >
    <title v-if="title">{{ title }}</title>
    <use
      :xlink:href="iconPath"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    />
  </svg>
</template>

<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
export default {
  name: 'Icon',

  props: {
    name: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    iconObject() {
      let icon = require(`@/assets/icons/${this?.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default;
      }
      return icon;
    },
    iconPath() {
      return this.iconObject.url;
    },
    iconViewBox() {
      return this.iconObject.viewBox;
    },
    className() {
      return `icon icon_${this?.name}`;
    },
  },
};
</script>

<style>
.icon {
  fill: currentColor;
}
</style>
