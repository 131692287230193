<template>
  <PageSection
    class="screen fleet-section"
    :theme="true"
    :bg="undefined"
  >
    <template #default>
      <div class="container">
        <h2
          class="title"
          v-if="false"
          v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 500}"
        >{{content?.Title}}</h2>
        <FleetTable
          :content="table"
          class="swiper-no-swiping"
          @open-popup="openPopup"
          v-inview-animate:repeat="{name: 'fade', duration: 500, delay: 700}"
        />

        <div class="links">
          <Link
            :tag="'a'"
            :to="'#noanchor'"
            v-inview-animate:repeat="{name: 'fade-down', duration: 500, delay: 900}"
            @click.prevent="openPopup(currentGroup)"
          >
          <template #default>{{content?.LinkText}}</template>
          <template #end>
            <Icon
              name="arrow"
              :style="'transition-delay: 900ms;'"
            />
          </template>
          </Link>
        </div>
      </div>
      <teleport to="#popups">
        <transition
          name="fade"
          mode="out-in"
        >
          <Popup
            v-if="popupActive"
            :isShown="popupActive"
            @modal-closed="popupActive = false"
            :class="'fullscreen'"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <FleetPopup
                :initialData="popupInit"
                :content="popupData"
              />
            </transition>
          </Popup>
        </transition>
      </teleport>
    </template>
  </PageSection>
</template>

<script>
import PageSection from '../../../components/PageSection.vue';
import Link from '../../../components/Link.vue';
import Icon from '../../../components/Icon.vue';
import FleetTable from './FleetTable.vue';
import Popup from '../../../components/Popup.vue';
import FleetPopup from '../../../components/FleetPopup/FleetPopup.vue';

export default {
  components: {
    PageSection,
    Link,
    Icon,
    FleetTable,
    Popup,
    FleetPopup,
  },
  name: 'FleetSection',
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      popupActive: false,
      currentGroup: {
        group: this.groups?.[0]?.group,
        model: this.groups?.[0]?.helicopters?.[0]?.Model,
      },
      popupInit: this.currentGroup,
    };
  },
  computed: {
    headers() {
      const params = this.$store.getters.getFleetParams;
      return {
        Brand: params?.Brand,
        Model: params?.Model,
        Year: params?.Year,
        RegistrationNumber: params?.RegistrationNumber,
      };
    },
    fleetGroups() {
      return this.$store.getters.getFleetGroups;
    },
    groups() {
      const content = this.content?.Fleet;
      let result = [];
      if (content) {
        const groups = Array.from(new Set(content.map((el) => el.fleet_group)));
        result = groups.map((el) => {
          const helicopters = content.filter((hel) => hel.fleet_group === el);
          const currGroup = this.fleetGroups.filter((gr) => gr.id === el)[0];
          return {
            group: el,
            name: currGroup?.Brand?.Name,
            logo: currGroup?.Brand?.Logo,
            helicopters,
          };
        });
      }
      return result;
    },
    modelGroups() {
      const content = this.content?.Fleet;
      let result = [];
      if (content) {
        const groups = Array.from(new Set(content.map((el) => el.fleet_group)));
        result = groups.map((el) => {
          const helicopters = content.filter((hel) => hel.fleet_group === el);
          const modelsB = Array.from(
            new Set(helicopters.map((hel) => hel.Model)),
          );
          const models = modelsB.map((mod) => this.$store.getters.getFleetModelById(mod));
          const currGroup = this.fleetGroups.filter((gr) => gr.id === el)[0];
          return {
            group: el,
            name: currGroup?.Brand?.Name,
            preview3d: currGroup?.Picture,
            path: currGroup?.ModelHtmlRelativePath,
            models,
          };
        });
      }
      return result;
    },
    charNames() {
      const params = this.$store.getters.getFleetParams;
      return {
        MaxSpeed: params?.MaxSpeed,
        MaxDistance: params?.MaxDistance,
        Passengers: params?.Passengers,
        TrunkVolume: params?.TrunkVolume,
      };
    },
    table() {
      return {
        headers: this.headers,
        groups: this.groups,
      };
    },
    popupData() {
      return {
        charNames: this.charNames,
        groups: this.modelGroups,
      };
    },
  },
  methods: {
    openPopup(data) {
      this.popupActive = true;
      this.popupInit = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.fleet-section {
  display: flex;
  will-change: transform, z-index;
  backface-visibility: hidden;

  @include wRule(
    padding,
    (
      xl: pxtovh(188, xl) 0 pxtovh(142, xl),
      lg: pxtovh(124, lg) 0 pxtovh(111, lg),
      md: pxtovh(134, md) 0 pxtovh(115, lg),
      sm: 76 0 143,
    )
  );

  @include screen("sm", max) {
    display: block;
    min-height: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .fleet-table {
    @include wRule(
      margin,
      (
        xl: pxtovh(50, xl) 0,
        lg: pxtovh(30, lg) 0 pxtovh(50, lg),
        md: pxtovh(45, md) 0,
        sm: 85 0,
      )
    );
  }

  .links {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
